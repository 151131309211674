import * as React from "react";
import { Paper, TextField, FormControl, Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
export function DigitalSignConfigurationForm(props) {
    var config = (typeof (props.configValues) == "string") ? JSON.parse(props.configValues) : props.configValues;
    return (React.createElement(Paper, { style: { paddingTop: 25 } },
        React.createElement("form", { onSubmit: props.onSave },
            React.createElement(Grid, { container: true, spacing: 8 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { alignItems: "center", style: { textAlign: "center" }, container: true, justify: "center", spacing: 4 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "fontColor", name: "fontColor", variant: "outlined", label: "Font color", value: config.fontColor, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "backgroundColor", name: "backgroundColor", variant: "outlined", label: "Background color", value: config.backgroundColor, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "promotionInterval", name: "promotionInterval", variant: "outlined", label: "Promotion Interval (s)", value: config.promotionInterval, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 10, sm: 10, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "connectionErrorMessage", name: "connectionErrorMessage", variant: "outlined", label: "Connection Error Message", value: config.connectionErrorMessage, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                            React.createElement(FormControl, null,
                                React.createElement(Button, { style: { maxWidth: "80px", margin: "auto" }, variant: "contained", size: "small", color: "primary", onClick: props.onSave },
                                    React.createElement(SaveIcon, null),
                                    "Save")))))))));
}
