import React, { Component } from "react";
import canadaFlag from "../../../Content/Icons/ca.svg";
import newZealandFlag from "../../../Content/Icons/nz.svg";
function isMobile() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /mobile|iphone|ipad|android/.test(userAgent);
}
const styles = {
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    countryBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 16px",
        padding: "16px",
        borderRadius: "16px",
        cursor: "pointer",
        border: "2px solid grey",
        background: "rgba(0,0,0,.5)",
    },
    selectedCountryBox: {
        borderColor: "rgb(191,13,62)",
    },
    countryName: {
        marginTop: "8px",
        fontWeight: "bold",
    },
    flagImage: {
        height: "64px",
    },
    questionText: {
        paddingTop: 15,
    },
};
class CountrySelection extends Component {
    constructor(props) {
        super(props);
        this.handleCountryClick = (event, country) => {
            this.setState({ selectedCountry: country });
            this.props.onSelect(country);
        };
        this.state = {
            selectedCountry: "",
        };
    }
    render() {
        return (React.createElement("div", { style: styles.mainContainer },
            React.createElement("div", { style: { minWidth: isMobile() ? 250 : 350, maxWidth: isMobile() ? 250 : 350, height: isMobile() ? 150 : 250, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: "url('/Content/Images/TG_hero_logo-898x180.png')", backgroundPosition: isMobile() ? "center" : "center" } }),
            React.createElement("div", { style: styles.container },
                React.createElement("div", { style: Object.assign(Object.assign({}, styles.countryBox), (this.state.selectedCountry === "ca"
                        ? styles.selectedCountryBox
                        : {})), onClick: (event) => this.handleCountryClick(event, "ca") },
                    React.createElement("img", { style: styles.flagImage, src: canadaFlag, alt: "Canada flag" }),
                    React.createElement("div", { style: styles.countryName }, "Canada")),
                React.createElement("div", { style: Object.assign(Object.assign({}, styles.countryBox), (this.state.selectedCountry === "nz"
                        ? styles.selectedCountryBox
                        : {})), onClick: (event) => this.handleCountryClick(event, "nz") },
                    React.createElement("img", { style: styles.flagImage, src: newZealandFlag, alt: "New Zealand flag" }),
                    React.createElement("div", { style: styles.countryName }, "New Zealand"))),
            React.createElement("div", { style: styles.questionText }, "Please select your Country.")));
    }
}
export default CountrySelection;
