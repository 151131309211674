import * as React from "react";
import { ServiceSelection } from './ServiceSelection';
import { ServiceSelectionMobile } from '../Mobile/ServiceSelectionMobile';
import { BarberSelection } from "./BarberSelection";
import { Button } from "@material-ui/core";
import { CheckinSummary } from "./CheckinSummary";
import { CheckinSummaryMobile } from "../Mobile/CheckinSummaryMobile";
import IdleUserDialog from "./IdleUserDialog";
var CheckinStep;
(function (CheckinStep) {
    CheckinStep[CheckinStep["ServiceSelection"] = 0] = "ServiceSelection";
    CheckinStep[CheckinStep["BarberSelection"] = 1] = "BarberSelection";
    CheckinStep[CheckinStep["Summary"] = 2] = "Summary";
    CheckinStep[CheckinStep["ChangeGuest"] = 3] = "ChangeGuest";
    CheckinStep[CheckinStep["Complete"] = 4] = "Complete";
})(CheckinStep || (CheckinStep = {}));
export class CustomerCheckin extends React.Component {
    constructor(props) {
        super(props);
        this.idleTick = 0;
        this.state = {
            step: CheckinStep.ServiceSelection,
            userIsIdle: false,
            isMobile: props.isMobile,
        };
        this.maxIdleTick = this.props.config.idleThreshold || 30;
        this.abandonedSessionThreshold = this.props.config.abandonSessionThreshold || (this.maxIdleTick * 2);
        this.userIdleThrottler();
        this.intervalId = setInterval(() => this.timerIncrement(), 1000);
    }
    componentDidMount() {
        this.bindUserIdleTimer();
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
        this.removeuserIdletimer();
    }
    selectServices(services) {
        // TODO: Handle no available services gracefully
        this.setState({
            step: CheckinStep.BarberSelection,
            services: services
        });
    }
    onCheckinSummaryConfirm(user) {
        //If user is null/undefined all guests have been checked in.
        if (!user) {
            this.setState({
                step: CheckinStep.Complete
            });
        }
        else {
            //Otherwise set selected guest as current guest and trigger service selection step.
            this.props.onChangeCurrentUser(user);
            this.setState({
                step: CheckinStep.ServiceSelection
            });
        }
    }
    bindUserIdleTimer() {
        window.addEventListener('mousemove', (e) => this.userIdleThrottler());
        window.addEventListener('keypress', (e) => this.userIdleThrottler());
        window.addEventListener('touchstart', (e) => this.userIdleThrottler());
        window.addEventListener('touchmove', (e) => this.userIdleThrottler());
        window.addEventListener('mousewheel', (e) => this.userIdleThrottler());
    }
    removeuserIdletimer() {
        window.removeEventListener('mousemove', (e) => this.userIdleThrottler());
        window.removeEventListener('keypress', (e) => this.userIdleThrottler());
        window.removeEventListener('touchstart', (e) => this.userIdleThrottler());
        window.removeEventListener('touhmove', (e) => this.userIdleThrottler());
        window.removeEventListener('mousewheel', (e) => this.userIdleThrottler());
    }
    userIdleThrottler() {
        let throttle;
        if (!throttle) {
            throttle = setTimeout(() => {
                this.resetIdleUser();
                clearTimeout(throttle);
                throttle = null;
            }, 100);
        }
    }
    timerIncrement() {
        this.idleTick++;
        if (this.idleTick > this.maxIdleTick) {
            if (this.idleTick >= this.abandonedSessionThreshold)
                this.props.onTimeOut();
            this.setState({
                userIsIdle: true
            });
        }
    }
    idlePopup() {
        if (this.state.userIsIdle) {
            return React.createElement(IdleUserDialog, { titleText: this.props.config.idleWindowTitle, dialogText: this.props.config.idleWindowText, open: true, onClose: (e) => this.resetIdleUser() });
        }
        return React.createElement(React.Fragment, null);
    }
    resetIdleUser() {
        this.idleTick = 0;
        this.setState({
            userIsIdle: false
        });
    }
    onCheckin() {
        if (this.props.onCheckin) {
            this.props.onCheckin();
        }
        this.setState({ step: CheckinStep.Summary });
    }
    onCancelCheckin() {
        this.props.onCheckinCancelled();
        this.setState({ step: CheckinStep.BarberSelection });
    }
    getStep() {
        if (this.state.step == CheckinStep.ServiceSelection)
            if (this.props.isMobile) {
                return React.createElement(ServiceSelectionMobile, { shopId: this.props.shopId, config: this.props.config, currentUser: this.props.currentUser, onSubmit: (s) => this.selectServices(s), onBackClick: () => this.props.onBackClick() });
            }
            else {
                return React.createElement(ServiceSelection, { shopId: this.props.shopId, config: this.props.config, currentUser: this.props.currentUser, onSubmit: (s) => this.selectServices(s), onBackClick: () => this.props.onBackClick() });
            }
        else if (this.state.step == CheckinStep.BarberSelection && this.state.services) {
            return React.createElement(BarberSelection, { shopId: this.props.shopId, config: this.props.config, currentUser: this.props.currentUser, onBarberSelection: (barber) => this.setState({ barber: barber }), selectedServices: this.state.services, onCheckin: () => this.onCheckin(), onBackClick: () => this.setState({ step: CheckinStep.ServiceSelection }), isMobile: this.props.isMobile });
        }
        else if (this.state.step == CheckinStep.Summary && this.state.barber && this.state.services) {
            if (this.props.isMobile) {
                return React.createElement(CheckinSummaryMobile, { config: this.props.config, shopId: this.props.shopId, barber: this.state.barber, services: this.state.services, user: this.props.currentUser, availableUsers: this.props.availableUsers, checkedInUsers: this.props.checkedInUsers, onConfirm: (user) => this.onCheckinSummaryConfirm(user), onComplete: () => this.props.onComplete(), onBack: () => this.onCancelCheckin(), isMobile: this.props.isMobile });
            }
            else {
                return React.createElement(CheckinSummary, { config: this.props.config, shopId: this.props.shopId, barber: this.state.barber, services: this.state.services, user: this.props.currentUser, availableUsers: this.props.availableUsers, checkedInUsers: this.props.checkedInUsers, onConfirm: (user) => this.onCheckinSummaryConfirm(user), onComplete: () => this.props.onComplete(), onBack: () => this.onCancelCheckin(), isMobile: this.props.isMobile, businessNumber: this.props.businessNumber });
            }
        }
        else {
            return (React.createElement("div", null,
                "No services were selected.",
                React.createElement(Button, { onClick: () => this.setState({ step: CheckinStep.ServiceSelection }) }, "Services")));
        }
    }
    render() {
        return (React.createElement(React.Fragment, null,
            this.idlePopup(),
            this.getStep()));
    }
}
