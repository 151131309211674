import * as React from 'react';
import MaterialTable from 'material-table';
import { EntityTable } from './EntityTable';
import { MuiThemeProvider } from '@material-ui/core';
import { ChangeRegionWindow } from "../Windows/ChangeRegionWindow";
export class TreeTable extends EntityTable {
    constructor() {
        super(...arguments);
        this.depth = false;
    }
    render() {
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            this.state && this.state.openedRow != null &&
                React.createElement(ChangeRegionWindow, { open: this.state.modalOpen, onClose: () => this.setState({ openedRow: null, modalOpen: false }), onSave: (newData, oldData) => this.onUpdate(newData, oldData), record: this.state.openedRow }),
            React.createElement(MaterialTable, { title: this.props.tableTitle, columns: this.props.columns, data: (query) => this.updateData(query)
                    .then(r => {
                    return r;
                }), key: this.props.tableTitle, parentChildData: (row, rows) => {
                    for (var i = 0; i < rows.length; i += 1) {
                        if (row[this.props.parentFieldName] == rows[i].id)
                            return rows[i];
                    }
                }, options: {
                    headerStyle: {
                        backgroundColor: 'transparent'
                    },
                    loadingType: 'linear',
                }, onSelectionChange: (data) => console.log(data), onRowClick: (data) => console.log(data), actions: this.getTableActions(), editable: {
                    onRowAdd: (data) => this.onAdd(data),
                    onRowUpdate: (newData, oldData) => this.onUpdate(newData, oldData),
                    onRowDelete: (data) => this.onDelete(data),
                } })));
    }
}
