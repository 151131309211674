import * as React from "react";
import { Grid, Paper, FormControl, TextField, Button, Snackbar } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { ApiUtils } from "../../../../Services/ApiUtils";
import { LoadingSpinner } from "../../../Common/Pages/LoadingSpinner";
export class MobileConfiguration extends React.Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        // GET Config from server
        ApiUtils.get('kiosk/GetMobileConfiguration').then(r => {
            if (!r.data) {
                console.log('no data');
                // Default state
                this.setState({
                    promotionInterval: 0,
                    saveSuccess: false
                });
            }
            else {
                console.log('got data');
                this.setState({
                    promotionInterval: r.data.promotionInterval,
                    saveSuccess: false
                });
            }
        });
    }
    onChange(fieldName, value) {
        var currState = this.state;
        currState[fieldName] = value;
        this.setState(currState);
    }
    onSave() {
        console.log('on save');
        // POST Config to Server
        var data = {
            promotionInterval: +this.state.promotionInterval
        };
        console.log(data);
        ApiUtils.post('Mobile/SaveConfiguration', data).then(r => {
            this.setState({
                saveSuccess: true
            });
        });
    }
    render() {
        if (!this.state)
            return React.createElement(LoadingSpinner, null);
        return (React.createElement(Paper, { style: { paddingTop: 25 } },
            React.createElement("form", null,
                React.createElement(Grid, { alignItems: "center", style: { textAlign: "center", maxWidth: '100%' }, container: true, justify: "center", spacing: 8 },
                    React.createElement(Grid, { item: true, xs: 10 },
                        React.createElement(FormControl, null,
                            React.createElement(TextField, { id: "promotionInterval", name: "promotionInterval", variant: "outlined", label: "Promotion Interval (s)", value: this.state.promotionInterval, onChange: (event) => this.onChange("promotionInterval", event.target.value) }))),
                    React.createElement(Grid, { item: true, xs: 10, justify: "center" },
                        React.createElement(Button, { style: { maxWidth: "80px", margin: "auto" }, variant: "contained", size: "small", color: "primary", onClick: () => this.onSave() },
                            React.createElement(SaveIcon, null),
                            "Save")))),
            React.createElement(Snackbar, { anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }, open: this.state.saveSuccess, onClose: () => this.setState({ saveSuccess: false }), autoHideDuration: 60000, message: React.createElement("span", { id: "form-success-text" }, "Save successful") })));
    }
}
