import * as React from "react";
import { Grid } from "@material-ui/core";
export default class ClosedShop extends React.Component {
    get12HrTime(day, time) {
        var time24 = this.props.shopHours[day][time];
        if (!time24) {
            return null;
        }
        var ts = time24;
        var H = +ts.substr(0, 2);
        var h = (H % 12) || 12;
        var ampm = H < 12 ? " AM" : " PM";
        ts = h + ts.substr(2, 3) + ampm;
        return ts;
    }
    getShopHours(day, styles) {
        var hourText = this.props.shopHours[day.toLowerCase()]["is_closed"] ? "Closed" : `${this.get12HrTime(day.toLowerCase(), "open_time")} - ${this.get12HrTime(day.toLowerCase(), "close_time")}`;
        return (React.createElement("h1", { className: "kiosk-title", style: styles },
            day,
            ": ",
            hourText));
    }
    createShopHours() {
        var styles = {
            padding: 6,
            fontSize: 30,
            fontFamily: 'Big Noodle Titling',
            textAlign: "center",
            margin: 0,
            width: "100%"
        };
        var days = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
        ];
        if (this.props.shopHours) {
            return (React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                React.createElement("h1", { className: "kiosk-title", style: styles }, this.props.shopName ? this.props.shopName + " hours are" : "Our shop hours are:"),
                days.map(d => this.getShopHours(d, styles))));
        }
        else {
            return;
        }
    }
    render() {
        var shopTitle = this.props.shopName ? this.props.shopName : "this shop";
        var closedText = React.createElement(React.Fragment, null,
            "Sorry",
            React.createElement("br", null),
            shopTitle,
            React.createElement("br", null),
            "is currently ",
            this.props.shopFull ? "full" : "closed");
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                React.createElement("h1", { className: "kiosk-title", style: { padding: 8, color: "#BF0D3E", fontSize: 45, fontFamily: 'Big Noodle Titling', textAlign: "center", margin: 0, width: "100%" } },
                    this.props.closedShopText ? this.props.closedShopText : closedText,
                    React.createElement("br", null))),
            this.createShopHours()));
    }
}
