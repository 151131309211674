import * as React from "react";
import { Done } from "@material-ui/icons";
export function KioskUserProfile(props) {
    var user = props.user;
    var checked = user.checkedIn ? React.createElement(Done, null) : (React.createElement("span", null));
    var size = props.sizeOverride || 75;
    var profileBlockStyle = {
        paddingRight: 10,
        color: props.useDarkText ? '#222' : 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };
    return (React.createElement("div", { className: "rerender", style: { textAlign: "center" } },
        React.createElement("div", { className: "profile-block", style: profileBlockStyle },
            React.createElement("div", { style: { padding: 12 } },
                React.createElement("div", { style: { width: size, height: size, margin: 'auto', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url('/Content/Images/${props.selected == true ? 'mustache-man-circle-rmus' : props.checkedIn == true ? 'mustache-man-red' : 'mustache-man'}.png')` } }),
                checked),
            React.createElement("div", { className: "rerender", style: { height: 40, textAlign: "center", fontFamily: 'Gotham Medium', textTransform: 'uppercase' } },
                user.firstName,
                " ",
                user.lastName ? user.lastName[0] : ''))));
}
