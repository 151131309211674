import * as React from "react";
import { Grid } from '@material-ui/core';
import { ApiUtils } from '../../../Services/ApiUtils';
import { BarberSquare } from "./BarberSquare";
import { ServiceSelectionStep } from "./ServiceSelection";
import { LoadingSpinner } from "../../Common/Pages/LoadingSpinner";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
export class BarberSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: ServiceSelectionStep.Loading
        };
    }
    getBarbers() {
        ApiUtils.get(`kiosk/GetBarbersForShop/${this.props.shopId}`)
            .then(r => {
            if (r.data) {
                this.setState({
                    barbers: r.data.employees,
                    step: ServiceSelectionStep.Loaded
                });
            }
            else {
                this.setState({
                    step: ServiceSelectionStep.Failed
                });
            }
        });
    }
    componentWillMount() {
        this.getBarbers();
    }
    onSelection(barber) {
        this.setState({
            selectedBarber: barber
        });
        this.props.onBarberSelection(barber);
    }
    onConfirm() {
        if (!this.state.selectedBarber)
            return;
        this.props.onCheckin();
    }
    renderButtons() {
        if (this.props.isMobile) {
            return (React.createElement("div", { style: { textAlign: "right", display: "flex", width: "100vw", justifyContent: "space-evenly", marginTop: 30 } },
                React.createElement("div", { className: "tg-back-button", onClick: (event) => this.props.onBackClick(), style: { position: "unset", display: "flex", width: this.props.isMobile ? 125 : 150 } },
                    React.createElement("div", { className: "tg-button-arrow-container", style: { position: "relative" } },
                        React.createElement(ArrowBackIosIcon, { className: "tg-back-button-arrow", style: { position: "unset" } })),
                    React.createElement("div", { className: "tg-back-button-text", style: { position: "unset" } }, "Back")),
                React.createElement("div", { className: "tg-next-button-mobile", onClick: (event) => this.onConfirm(), style: { position: "relative" } },
                    React.createElement("div", { style: { width: "100%", marginTop: -5, marginBottom: this.props.isMobile ? -5 : 0, letterSpacing: 5, fontSize: this.props.isMobile ? 40 : 45, fontFamily: "Big Noodle Titling" } }, "Next"),
                    React.createElement("div", { style: { width: "100%", textAlign: "center", textTransform: "uppercase", fontFamily: 'Gotham Book' } }, "Complete Check-in"))));
        }
        else {
            return (React.createElement(Grid, { item: true, xs: 6, justify: "center", style: { textAlign: "right", display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", padding: "15px 45px 15px 0px" } },
                React.createElement("div", { className: "tg-back-button", onClick: (event) => this.props.onBackClick() },
                    React.createElement("div", { className: "tg-button-arrow-container" },
                        React.createElement(ArrowBackIosIcon, { className: "tg-back-button-arrow" })),
                    React.createElement("div", { className: "tg-back-button-text" }, "Back")),
                React.createElement("div", { className: "tg-next-button", onClick: (event) => this.onConfirm() },
                    React.createElement("div", { style: { width: "100%", letterSpacing: 5, fontSize: 45, fontFamily: "Big Noodle Titling" } }, "Next"),
                    React.createElement("div", { style: { width: "100%", textAlign: "center", textTransform: "uppercase", fontFamily: 'Gotham Book', paddingBottom: "10px" } }, "Complete Check-in"))));
        }
    }
    render() {
        if (this.state.step == ServiceSelectionStep.Loading) {
            return (React.createElement(LoadingSpinner, null));
        }
        else if (this.state.step == ServiceSelectionStep.Failed || !this.state.barbers) {
            return (React.createElement("div", null,
                React.createElement("h1", null, "Failed to load Barbers.")));
        }
        return (React.createElement("div", null,
            React.createElement(Grid, { container: true, style: { height: "50%", textAlign: "center", padding: 25, paddingTop: this.props.isMobile ? 0 : 25, minHeight: 600 }, justify: "space-between", direction: "row", alignItems: "flex-start" },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement("div", { className: "tg-barbers-category", style: { maxWidth: this.props.isMobile ? "100%" : "50%", fontSize: 40, fontFamily: 'Big Noodle Titling', textAlign: 'left', borderBottom: '5px #BF0D3E solid', marginBottom: 30 } }, "Select Barber"),
                    React.createElement("div", { className: "tg-barbers" }, this.state.barbers.map(x => React.createElement(BarberSquare, { barber: x, hideWaitTime: this.props.hideWaitTime || x.name != "Next Available", onSelection: (barber) => this.onSelection(barber), selected: this.state.selectedBarber == x, overideSize: this.props.isMobile ? 110 : undefined, isMobile: this.props.isMobile })))),
                React.createElement(Grid, { item: true, xs: 6, justify: "center" }),
                this.renderButtons())));
    }
}
