import * as React from "react";
import { KioskUserProfile } from "./KioskUser";
import { Grid, IconButton } from "@material-ui/core";
import { AddCircle } from '@material-ui/icons';
export class GroupSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    renderUserProfile(user) {
        var selected = user == this.props.currentUser;
        var checkedIn = this.props.checkedInUsers.filter(u => u == user).length > 0;
        var style = {
            paddingRight: 10,
            color: 'white'
        };
        return (React.createElement(Grid, { item: true, xs: 3, style: style },
            React.createElement(KioskUserProfile, { user: user, selected: selected, checkedIn: checkedIn, sizeOverride: 50 })));
    }
    renderAddGuest() {
        var style = {
            paddingRight: this.props.isMobile ? 20 : 10,
            color: 'white'
        };
        var addGuestsBlockStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        };
        return (React.createElement(Grid, { item: true, xs: 3, style: style },
            React.createElement("div", { style: addGuestsBlockStyle },
                React.createElement("div", { style: { textAlign: 'center' } },
                    React.createElement(IconButton, { onClick: () => this.props.onAddGuest() },
                        React.createElement(AddCircle, { style: { width: 50, height: 50, color: '#86898B' } }))),
                React.createElement("div", { className: "rerender", style: { height: 40, textAlign: "center", fontFamily: 'Gotham Medium', textTransform: 'uppercase' } }, "Add Guest"))));
    }
    render() {
        return (React.createElement(Grid, { container: true, justify: this.props.isMobile ? "flex-start" : "flex-end", style: { marginTop: this.props.isMobile ? 11 : 0, marginLeft: this.props.isMobile ? 9 : 0 } },
            this.props.users.map(u => this.renderUserProfile(u)),
            this.props.showAddGuest ? this.renderAddGuest() : React.createElement(React.Fragment, null)));
    }
}
