import { User, LoginModel } from "./User";
import { ApiUtils } from "./ApiUtils";
//Authentication service which is used throughout the application through an AuthContext
export class AuthService {
    constructor() {
        this.isSignedIn = false;
        this.currentUser = null;
        this.currentRegionName = 'None';
        this.getCurrentRegion = () => {
            return (ApiUtils.get('Accounts/CurrentRegion').then(r => {
                this.currentRegionName = r.data.name;
                return r.data;
            }));
        };
        this.parseUserData = (userData) => {
            if (!userData)
                return;
            var storedUser = JSON.parse(userData);
            if (!storedUser)
                return;
            this.currentUser = new User(storedUser.email, storedUser.token, storedUser.userRole, storedUser.TwoFactorEnabled, storedUser.regionId, storedUser.regionName);
            ApiUtils.configure(storedUser.token);
        };
        this.parseUserData(sessionStorage['data']);
    }
    updateCurrentRegion() {
        return this.getCurrentRegion();
    }
    isAuthenticated(roles) {
        if (this.currentUser == null) {
            this.parseUserData(sessionStorage['data']);
        }
        else {
            ApiUtils.configure(this.currentUser.token);
        }
        return (!roles) ? (this.currentUser != null) : (this.currentUser && this.currentUser.matchesAnyRole(roles));
    }
    toggleTwoFactor() {
        if (this.currentUser == null)
            return;
        else if (this.currentUser.tfaEnabled) {
            ApiUtils.post('Accounts/DisableTwoFactor');
        }
        else {
            ApiUtils.post('Accounts/EnableTwoFactor');
        }
    }
    signIn(email, password) {
        return ApiUtils.post('Accounts/Authenticate', new LoginModel(email, password), {}, User.prototype).then(response => {
            this.currentUser = response.data;
            this.isSignedIn = true;
            ApiUtils.configure(this.currentUser.token);
            sessionStorage.setItem('data', JSON.stringify(this.currentUser));
        });
    }
    updateRegion(regionId, regionName) {
        if (this.currentUser == null)
            return;
        else {
            this.currentUser.regionName = regionName;
            this.currentUser.regionId = regionId;
        }
    }
    signOut() {
        this.isSignedIn = false;
        this.currentUser = null;
        sessionStorage.removeItem('data');
        window.location.reload();
    }
}
