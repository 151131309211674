import * as React from "react";
import { Grid, Button, TextField, FormControl } from '@material-ui/core';
import { ApiUtils } from '../../../Services/ApiUtils';
import { GroupCreator } from "./GroupCreator";
import { UserSelector } from "./UserSelector";
//import  OnScreenKeyboard from './OnScreenKeyboard';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from "react-simple-keyboard";
import { LoadingSpinner } from "../../Common/Pages/LoadingSpinner";
import { parsePhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
var LoginStep;
(function (LoginStep) {
    LoginStep[LoginStep["Login"] = 0] = "Login";
    LoginStep[LoginStep["LoginFailed"] = 1] = "LoginFailed";
    LoginStep[LoginStep["PickGroup"] = 2] = "PickGroup";
    LoginStep[LoginStep["SelectUser"] = 3] = "SelectUser";
    LoginStep[LoginStep["AccountExists"] = 4] = "AccountExists";
    LoginStep[LoginStep["Authenticated"] = 5] = "Authenticated";
})(LoginStep || (LoginStep = {}));
export class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: props.region.toLowerCase() == 'ca' || props.region.toLowerCase() == 'us' ? "1" : "64",
            password: "",
            step: LoginStep.Login,
            availableUsers: [],
            selectedUsers: [],
            displayKeyboard: true,
            loading: false,
            errMsg: "",
            region: props.region.toLowerCase(),
            prefix: "",
        };
    }
    componentDidMount() {
        this.getWaitTime();
        setInterval(() => this.getWaitTime(), 1000 * 60); // TODO: Have this be configurable
    }
    authenticate() {
    }
    getWaitTime() {
        if (this.props.config.displayWaitTime) {
            ApiUtils.get(`kiosk/GetWaitTimeForShop/${this.props.shopId}`).then(r => {
                if (r.status == 200 && r.data.waitTime != null) {
                    if (this.props.config.waitTimeOffset && this.props.config.waitTimeOffset > 0) {
                        var baseWaitTime = r.data.waitTime * 60;
                        var queueSize = r.data.queueSize;
                        var offset = this.props.config.waitTimeOffset;
                        var min = Math.ceil((Math.max(0, (baseWaitTime - (queueSize * offset)))) / 60);
                        var max = Math.ceil((baseWaitTime + (queueSize * offset)) / 60);
                        var range = min == max ? `${max}` : `${min} - ${max}`;
                        this.setState({ waitTime: range });
                    }
                    else
                        this.setState({ waitTime: `${r.data.waitTime}` });
                }
            });
        }
    }
    validatePhoneNumber(number, language) {
        const validLanguages = ['us', 'ca', 'nz', 'au'];
        if (!validLanguages.includes(language)) {
            return;
        }
        const parsedNumber = parsePhoneNumber(number, language.toUpperCase());
        if (!parsedNumber || !(parsedNumber === null || parsedNumber === void 0 ? void 0 : parsedNumber.isValid())) {
            return;
        }
        const fixedNumber = parsedNumber.format('NATIONAL');
        const formattedNumber = parsedNumber.format('E.164');
        if (fixedNumber !== number) {
            return formattedNumber;
        }
        return number;
    }
    onCheckin() {
        try {
            this.setState({ loading: true });
            const isValidPTN = this.validatePhoneNumber(this.state.phoneNumber, this.state.region);
            if (isValidPTN === undefined) {
                this.setState({
                    step: LoginStep.LoginFailed,
                    loading: false,
                    errMsg: "The number entered is not valid."
                });
                return;
            }
            var apiPhoneNumber = isValidPTN.replace('+1', '').replace('+', '');
            ApiUtils.get(`kiosk/GetAccountsForPhoneNumber/${apiPhoneNumber}`)
                .then(r => {
                if (r.status == 200) {
                    if (r.data && r.data.length > 0) {
                        this.setState({
                            step: LoginStep.PickGroup,
                            availableUsers: r.data,
                            currentUser: r.data[0],
                            selectedUsers: [r.data[0]]
                        });
                    }
                    else {
                        this.props.onRegisterClick(this.state.phoneNumber, true, this.state.region);
                    }
                }
                this.setState({ loading: false });
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
        catch (e) {
            this.setState({
                loading: false,
                errMsg: "Oops, we ran into an issue.  Please try again shortly."
            });
        }
    }
    onLogin() {
        if (this.state && this.state.currentUser) {
            var credentials = {
                username: this.state.currentUser.email,
                password: this.state.password
            };
            try {
                this.setState({ loading: true });
                ApiUtils.post(`kiosk/Authenticate`, credentials)
                    .then(r => {
                    if (r.data) {
                        var user = r.data;
                        user.phoneNumber = this.state.phoneNumber;
                        this.props.onAuthenticate({ currentUser: user, group: this.state.selectedUsers });
                    }
                    else {
                        this.setState({
                            step: LoginStep.LoginFailed
                        });
                    }
                    this.setState({ loading: false });
                });
            }
            catch (e) {
                this.setState({
                    step: LoginStep.LoginFailed,
                    loading: false
                });
            }
        }
    }
    onPasswordInput(event) {
        this.setState({
            password: event.target.value
        });
    }
    onBackClick() {
        this.setState({
            step: LoginStep.Login
        });
    }
    passwordEntry() {
        var username = this.state.currentUser ? this.state.currentUser.firstName : 'Guest';
        return (React.createElement(Grid, { container: true, style: { height: "50%", margin: "auto" }, justify: "center", direction: "column", alignItems: "center" },
            React.createElement(Grid, { item: true, xs: 4, justify: "center" },
                React.createElement("div", null,
                    React.createElement("h1", { className: "kiosk-title", style: { color: this.props.config.fontColor || "white", textAlign: "center", margin: 0, width: "100%" } },
                        "Welcome ",
                        username,
                        "!"),
                    React.createElement("div", { className: "kiosk-login-text" }, "Please enter in your password to continue"))),
            React.createElement(Grid, { item: true, xs: 4, justify: "center" },
                React.createElement(FormControl, null,
                    React.createElement(TextField, { fullWidth: true, type: "password", id: "password", name: "password", variant: "outlined", value: this.state.password, className: "wallboard-config-input", onChange: (event) => this.onPasswordInput(event), inputProps: { readOnly: true } }),
                    this.state.step == LoginStep.LoginFailed ? 'Incorrect password' : '')),
            React.createElement(Grid, { item: true, xs: 4, justify: "center" },
                React.createElement(Button, { disableRipple: true, style: { margin: "auto" }, variant: "contained", size: "large", color: "secondary", onClick: (event) => this.onLogin() }, "Login"),
                React.createElement(Button, { style: { margin: "auto", marginLeft: "15px" }, onClick: (event) => this.onBackClick(), variant: "contained", size: "large", color: "secondary" }, "Back"))));
    }
    renderWaitTime() {
        if (this.props.config.displayWaitTime) {
            var styling = {
                padding: 0,
                paddingBottom: 25,
                color: "#BF0D3E",
                fontSize: 35,
                fontFamily: 'Big Noodle Titling',
                textAlign: "center",
                margin: 0,
                width: "100%",
                marginBottom: -44
            };
            return (React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                React.createElement("h2", { className: "kiosk-title", style: {
                        padding: 0, paddingBottom: 25, paddingTop: this.props.isMobile ? 11 : 0,
                        color: "#BF0D3E", fontSize: this.props.isMobile ? 30 : 35, fontFamily: 'Big Noodle Titling', textAlign: "center", margin: 0, width: "100%", marginBottom: this.props.isMobile ? -22 : -44
                    } },
                    "Estimated Wait Time: ",
                    this.state.waitTime == undefined ? "Calculating..." : `${this.state.waitTime} mins`)));
        }
        return React.createElement(React.Fragment, null);
    }
    showKeyboard() {
        this.setState({ displayKeyboard: true });
    }
    getStep() {
        if ((this.state.step == LoginStep.AccountExists || this.state.step == LoginStep.LoginFailed) && this.state.currentUser) {
            return this.passwordEntry();
        }
        else if (this.state.step == LoginStep.PickGroup && this.state.availableUsers) {
            return React.createElement(GroupCreator, { users: this.state.availableUsers, onCancelClick: () => this.setState({ step: LoginStep.Login }), onConfirmClick: (users) => this.setState({ step: LoginStep.SelectUser, selectedUsers: users }), lightTheme: false, isMobile: this.props.isMobile });
        }
        else if (this.state.step == LoginStep.SelectUser && this.state.selectedUsers) {
            return React.createElement(UserSelector, { onConfirm: (user) => {
                    this.props.onAuthenticate({ currentUser: user, group: this.state.selectedUsers });
                }, isMobile: this.props.isMobile, users: this.state.selectedUsers, onComplete: this.props.onComplete });
        }
        else {
            const { phoneNumber } = this.state;
            const allowedCountries = ['ca', 'nz', 'us', 'au'];
            const onChangeInput = (value, country) => {
                this.setState({
                    phoneNumber: value, region: country.countryCode, prefix: country.dialCode
                });
            };
            const onKeyboardChange = (input) => {
                if (input === "{bksp}") {
                    if (this.state.phoneNumber != this.state.prefix) {
                        this.setState({ phoneNumber: this.state.phoneNumber.slice(0, -1) });
                    }
                }
                else {
                    this.setState({ phoneNumber: this.state.phoneNumber + input });
                }
            };
            const updatePrefix = (value, country) => {
                this.setState({ prefix: country.dialCode });
            };
            return (React.createElement(React.Fragment, null,
                this.renderWaitTime(),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                    React.createElement("h1", { className: "kiosk-title", style: { padding: 0, color: "#BF0D3E", fontSize: this.props.isMobile ? 43 : 60, fontFamily: 'Big Noodle Titling', textAlign: "center", margin: 0, width: "100%" } }, this.props.config.loginWelcomeText || "Check-in"),
                    React.createElement("h2", { style: { padding: "0px 0px 15px 0px", color: "rgb(191, 13, 62)", fontSize: "20px", fontFamily: "Big Noodle Titling", textAlign: "center", margin: "0px", width: "100%" } }, "Enter your Mobile Number")),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                    React.createElement("div", { style: { display: "inline-block", verticalAlign: "top", color: "black" } },
                        React.createElement(PhoneInput, { country: this.state.region, value: phoneNumber, onlyCountries: allowedCountries, onChange: onChangeInput, countryCodeEditable: false, disableCountryGuess: true, inputProps: { name: 'phone', required: true, autoFocus: true }, onMount: updatePrefix, placeholder: this.state.prefix })),
                    React.createElement("div", { style: { color: 'black' } }, this.state.step == LoginStep.LoginFailed ? this.state.errMsg : "")),
                this.renderBeginButton(),
                React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { color: 'black' } }, this.state.displayKeyboard ?
                    React.createElement("div", null,
                        React.createElement(Keyboard, { inputName: "phone", onKeyReleased: onKeyboardChange, layout: { default: ["1 2 3", "4 5 6", "7 8 9", '0 {bksp}'] }, baseClass: "tg-mobile-keyboard", keyboardRef: r => (this.keyboard = r) }))
                    :
                        React.createElement(React.Fragment, null))));
        }
    }
    renderBeginButton() {
        if (this.state.loading) {
            return (React.createElement(Grid, { item: true, xs: 12, style: { margin: "25px auto" } },
                React.createElement(LoadingSpinner, null)));
        }
        return (React.createElement(Grid, { item: true, xs: 12, justify: "center" },
            React.createElement(Button, { disableRipple: true, style: { margin: "25px auto", fontFamily: 'Gotham Book' }, onClick: (event) => this.onCheckin(), variant: "contained", size: "large", color: "secondary" }, "Begin")));
    }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, style: { width: this.props.isMobile ? "89%" : "50%", margin: "auto" }, justify: "space-between", direction: "row", alignItems: "center" },
                React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { paddingTop: 15, paddingBottom: 15 } },
                    React.createElement("div", { className: "tg-kiosk-logo", style: {
                            height: this.props.isMobile ? 150 : 250, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: "url('/Content/Images/TG_hero_logo-898x180.png')",
                            backgroundPosition: "center"
                        } }))),
            React.createElement(Grid, { container: true, style: { width: this.props.isMobile ? "89%" : "50%", margin: "auto", borderRadius: 25, textAlign: "center", backgroundColor: 'rgba(255, 255, 255, 0.8)' }, justify: "space-between", direction: "row", alignItems: "center" }, this.getStep())));
    }
}
