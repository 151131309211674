import * as React from "react";
import { EntityTable } from "../../../Common/Tables/EntityTable";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { TableType } from "../../../../Services/TableStateManager";
export class ReportedIssuesTable extends React.Component {
    constructor() {
        super(...arguments);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
        this.tableProps = {
            type: TableType.ReportedIssues,
            apiController: "ReportedIssue",
            tableTitle: "Reported Issues",
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'ShortcutsId', field: 'shorcutsId', type: 'numeric' },
                { title: 'Phone Number', field: 'phoneNumber' },
                { title: 'Version', field: 'versionNumber' },
                { title: 'Description', field: 'description' },
            ],
            actions: [],
        };
    }
    render() {
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(EntityTable, Object.assign({}, this.tableProps))));
    }
}
