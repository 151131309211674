import * as React from "react";
import { Grid, Button, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import { ApiUtils } from '../../../Services/ApiUtils';
import { LoadingSpinner } from "../../Common/Pages/LoadingSpinner";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
export var ServiceSelectionStep;
(function (ServiceSelectionStep) {
    ServiceSelectionStep[ServiceSelectionStep["Loading"] = 0] = "Loading";
    ServiceSelectionStep[ServiceSelectionStep["Loaded"] = 1] = "Loaded";
    ServiceSelectionStep[ServiceSelectionStep["Failed"] = 2] = "Failed";
    ServiceSelectionStep[ServiceSelectionStep["Selected"] = 3] = "Selected";
})(ServiceSelectionStep || (ServiceSelectionStep = {}));
export class ServiceSelectionMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: ServiceSelectionStep.Loading,
            services: {
                haircuts: [],
                shaves: [],
                detailing: [],
            },
            disabledServices: []
        };
    }
    selectHaircut(service) {
        if (!this || !this.state || !this.state.services || !this.state.services.haircuts)
            return;
        var index = this.state.services.haircuts.indexOf(service);
        var choice = this.state.services.haircuts[index];
        choice.selected = !choice.selected;
        var disabledServices = this.state.disabledServices;
        if (choice.selected) {
            disabledServices = disabledServices.concat(choice.relations);
        }
        else {
            disabledServices = disabledServices.filter(ds => !(choice.relations.filter(r => r == ds).length > 0));
        }
        var services = this.state.services;
        if (services && services.haircuts)
            services.haircuts[index] = choice;
        this.setState({
            services: services,
            disabledServices: disabledServices
        });
    }
    selectShave(service) {
        if (!this || !this.state || !this.state.services.shaves)
            return;
        var index = this.state.services.shaves.indexOf(service);
        var choice = this.state.services.shaves[index];
        choice.selected = !choice.selected;
        var disabledServices = this.state.disabledServices;
        if (choice.selected) {
            disabledServices = disabledServices.concat(choice.relations);
        }
        else {
            disabledServices = disabledServices.filter(ds => !(choice.relations.filter(r => r == ds).length > 0));
        }
        var services = this.state.services;
        if (services && services.shaves)
            services.shaves[index] = choice;
        this.setState({
            services: services,
            disabledServices: disabledServices
        });
    }
    selectDetailing(service) {
        if (!this || !this.state || !this.state.services.detailing)
            return;
        var index = this.state.services.detailing.indexOf(service);
        var choice = this.state.services.detailing[index];
        choice.selected = !choice.selected;
        var disabledServices = this.state.disabledServices;
        if (choice.selected) {
            disabledServices = disabledServices.concat(choice.relations);
        }
        else {
            disabledServices = disabledServices.filter(ds => !(choice.relations.filter(r => r == ds).length > 0));
        }
        var services = this.state.services;
        if (services && services.detailing)
            services.detailing[index] = choice;
        this.setState({
            services: services,
            disabledServices: disabledServices
        });
    }
    selectServices() {
        if (!this.state.services) {
            // Need to display a helpful error message.
            console.log('No services available');
            return;
        }
        var haircuts = this.state.services.haircuts.filter(s => s.selected == true);
        var shaves = this.state.services.shaves.filter(s => s.selected == true);
        var detailings = this.state.services.detailing.filter(s => s.selected == true);
        var selectedServices = haircuts.concat(shaves).concat(detailings);
        if (selectedServices.length == 0)
            return;
        this.props.onSubmit(selectedServices);
    }
    getServices() {
        ApiUtils.get(`kiosk/GetServicesForShop/${this.props.shopId}`)
            .then(r => {
            if (r.data) {
                this.setState({
                    services: r.data,
                    step: ServiceSelectionStep.Loaded
                });
            }
            else {
                this.setState({
                    step: ServiceSelectionStep.Failed
                });
            }
        });
    }
    componentWillMount() {
        this.getServices();
    }
    isServiceDisabled(id) {
        return this.state.disabledServices.filter(ds => ds == id).length > 0;
    }
    renderCategoryServices(services, onSelect) {
        var totalServices = services.length;
        var groups = [];
        for (var i = 0; i < totalServices; i += 4) {
            var maxIndex = Math.min(i, totalServices - 1);
            var groupServices = services.length > 4 ? services.slice(maxIndex, Math.min(i + 4, totalServices)) : services;
            groups.push(groupServices.map(x => React.createElement(ServiceSquare, { shopId: this.props.shopId, service: x, disabled: this.isServiceDisabled(x.id), widthOveride: services.length > 1 ? undefined : "144px", onSelection: () => onSelect(x) })));
        }
        return groups;
    }
    getServicesContainerWidth(services) {
        return services && services.length > 0 ? Math.ceil(services.length / 4.0) * 300 + 90 : 300;
    }
    render() {
        if (this.state.step == ServiceSelectionStep.Loading) {
            return (React.createElement(LoadingSpinner, null));
        }
        else if (this.state.step == ServiceSelectionStep.Failed) {
            return (React.createElement("div", null,
                React.createElement("h1", null, "Failed to load services.")));
        }
        var categoryStyles = {
            fontSize: 40,
            fontFamily: 'Big Noodle Titling',
            textAlign: 'left',
            marginBottom: 30,
            borderBottom: '5px #BF0D3E solid'
        };
        var containerStyles = {
            maxHeight: 600,
            width: "100vw",
            overflowX: "scroll",
        };
        var serviceStyles = {
            display: "flex",
            width: "95%"
        };
        return (React.createElement("div", null,
            React.createElement(Grid, { container: true, style: { height: "50%", textAlign: "center", padding: "0 10px", overflowX: "hidden" }, justify: "space-between", direction: "column", alignItems: "flex-start" },
                React.createElement(Grid, { className: "tg-category-container" },
                    React.createElement("div", { className: "tg-services-category", style: categoryStyles }, "Haircuts & Styling"),
                    (this.state.services.haircuts.length > 3) ? React.createElement("div", { className: "tg-services-overlay-mobile" }) : React.createElement(React.Fragment, null),
                    React.createElement("div", { className: "tg-services-container", style: containerStyles },
                        React.createElement("div", { className: "tg-services-mobile tg-services__haircuts", style: serviceStyles }, (this.state.services.haircuts) ? this.renderCategoryServices(this.state.services.haircuts, (service) => this.selectHaircut(service)) : React.createElement(React.Fragment, null)))),
                React.createElement(Grid, { className: "tg-category-container" },
                    React.createElement("div", { className: "tg-services-category", style: categoryStyles }, "Shaves"),
                    (this.state.services.shaves.length > 3) ? React.createElement("div", { className: "tg-services-overlay-mobile" }) : React.createElement(React.Fragment, null),
                    React.createElement("div", { className: "tg-services-container", style: containerStyles },
                        React.createElement("div", { className: "tg-services-mobile tg-services__shaves", style: serviceStyles }, (this.state.services.shaves) ? this.renderCategoryServices(this.state.services.shaves, (service) => this.selectShave(service)) : React.createElement(React.Fragment, null)))),
                React.createElement(Grid, { className: "tg-category-container" },
                    React.createElement("div", { className: "tg-services-category", style: categoryStyles }, "Detailing"),
                    (this.state.services.detailing.length > 3) ? React.createElement("div", { className: "tg-services-overlay-mobile" }) : React.createElement(React.Fragment, null),
                    React.createElement("div", { className: "tg-services-container", style: containerStyles },
                        React.createElement("div", { className: "tg-services-mobile tg-services__detaling", style: serviceStyles }, (this.state.services.detailing) ? this.renderCategoryServices(this.state.services.detailing, (service) => this.selectDetailing(service)) : React.createElement(React.Fragment, null)))),
                React.createElement(Grid, { item: true, xs: 6, justify: "center" }),
                React.createElement("div", { style: { textAlign: "right", display: "flex", width: "100vw", justifyContent: "space-evenly", marginTop: 30 } },
                    React.createElement("div", { className: "tg-back-button", onClick: (event) => this.props.onBackClick(), style: { position: "unset", display: "flex", width: "125px" } },
                        React.createElement("div", { className: "tg-button-arrow-container", style: { position: "relative" } },
                            React.createElement(ArrowBackIosIcon, { className: "tg-back-button-arrow", style: { position: "unset" } })),
                        React.createElement("div", { className: "tg-back-button-text", style: { position: "unset" } }, "Back")),
                    React.createElement("div", { className: "tg-next-button-mobile", onClick: (event) => this.selectServices(), style: { position: "relative" } },
                        React.createElement("div", { style: { width: "100%", marginTop: -5, marginBottom: -5, letterSpacing: 5, fontSize: 40, fontFamily: "Big Noodle Titling" } }, "Next"),
                        React.createElement("div", { style: { width: "100%", textAlign: "center", textTransform: "uppercase", fontFamily: 'Gotham Book' } }, "Choose Barber"))))));
    }
}
export class ServiceSquare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.service.selected || false,
            modalOpen: false
        };
        if (!this.props.service.description) {
            var data = {
                shopId: this.props.shopId,
                serviceId: this.props.service.id
            };
            ApiUtils.post(`kiosk/GetServiceDescription`, data).then(r => {
                if (r.status == 200 && r.data) {
                    this.setState({ descriptionOverride: r.data.toString() });
                }
            });
        }
    }
    onSelection() {
        // Do not select if the modal is open or if this Service is disabled.
        if (this.state.modalOpen || this.props.disabled)
            return;
        this.setState({
            selected: !this.state.selected
        });
        this.props.onSelection();
    }
    toggleModal(event) {
        event.stopPropagation();
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }
    renderImage(imageStyle) {
        if (this.props.service.assetId && this.props.service.assetId != "0") {
            return (React.createElement("div", { className: "tg-service-square__image" },
                React.createElement("img", { width: 100, height: 100, style: imageStyle, src: `/api/v1/asset/view/${this.props.service.assetId}`, alt: this.props.service.name })));
        }
    }
    render() {
        var selected = this.state.selected && this.props.selectOveride != true;
        var containerStyle = {
            cursor: this.props.disabled ? "not-allowed" : "pointer",
            filter: this.props.disabled ? "opacity(0.5) blur(1.5px)" : "",
            paddingBottom: 20,
            width: this.props.widthOveride || "45%",
            maxWidth: this.props.widthOveride || "144px",
            float: "left",
            marginLeft: 10
        };
        var serviceSquareStyle = {
            backgroundColor: selected ? '#2C2C2C' : '#fefefe',
            color: selected ? '#fefefe' : '#222',
            padding: 5,
            borderRadius: 10,
            border: selected ? "3px solid #BF0D3E" : ""
        };
        var imageStyle = {
            filter: `invert(${selected ? 1 : 0})`
        };
        var buttonStyle = {
            color: "#BF0D3E",
            textDecoration: "underline solid 1px #BF0D3E",
            textTransform: 'lowercase',
            backgroundColor: 'transparent'
        };
        return (React.createElement("div", { onClick: () => this.onSelection(), className: `tg-service-square-container ${selected ? "tg-selected" : ""} ${this.props.disabled ? "disabled" : ""} `, style: containerStyle },
            React.createElement("div", { className: "tg-service-square", style: serviceSquareStyle },
                this.renderImage(imageStyle),
                React.createElement("div", { className: "tg-service-square__price", style: { fontSize: 28, fontWeight: 800 } },
                    React.createElement("span", { style: { fontSize: 14, verticalAlign: "super" } }, "$"),
                    this.props.service.price),
                React.createElement("div", { className: "tg-service-square__info" },
                    React.createElement(Button, { variant: "text", color: "primary", style: buttonStyle, onClick: (e) => this.toggleModal(e) }, "Description")),
                React.createElement(Dialog, { onClose: (e) => this.toggleModal(e), open: this.state.modalOpen, style: { minHeight: 300 } },
                    React.createElement(DialogTitle, { style: { borderBottom: "1px solid #BF0D3E", paddingTop: 15 } },
                        React.createElement("div", { style: { paddingRight: 25 } }, this.props.service.name),
                        React.createElement(IconButton, { "aria-label": "close", onClick: (e) => this.toggleModal(e), style: { position: "absolute", right: 0, top: 0 } },
                            React.createElement(CloseIcon, null))),
                    React.createElement(DialogContent, { style: { textAlign: "center", paddingBottom: 15 } },
                        React.createElement("div", { className: "tg-service-square__image", style: { paddingTop: 25 } }, this.props.service.assetId && this.props.service.assetId != "0" ? React.createElement("img", { width: 200, height: 200, style: { filter: "invert(1) grayscale(1)" }, src: `/api/v1/asset/view/${this.props.service.assetId}`, alt: this.props.service.name }) : React.createElement(React.Fragment, null)),
                        React.createElement("div", { className: "tg-service-square__price", style: { fontSize: 55, fontWeight: 800, paddingBottom: 5 } },
                            React.createElement("span", { style: { fontSize: 20, verticalAlign: "super" } }, "$"),
                            this.props.service.price),
                        React.createElement("div", { style: { fontSize: 18, padding: "25px 0px" } }, this.state.descriptionOverride || this.props.service.description || "Loading Description..."),
                        React.createElement("div", null,
                            React.createElement(Button, { style: { fontFamily: 'Gotham Book' }, onClick: (e) => this.toggleModal(e), variant: "contained", size: "large", color: "secondary" }, "Close")))))));
    }
}
