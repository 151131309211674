import { AuthRole } from "./AuthRole";
export class User {
    constructor(email, token, role, isTwoFactor, region, regionName) {
        this.email = email;
        this.token = token;
        this.userRole = role;
        this.tfaEnabled = isTwoFactor;
        this.regionId = region;
        this.regionName = regionName;
    }
    matchesAnyRole(roles) {
        if (Array.isArray(roles)) {
            return roles.indexOf(this.userRole) !== -1 || roles.indexOf(AuthRole.Any) !== -1;
        }
        return this.userRole === roles || roles === AuthRole.Any;
    }
    TwoFactorEnabled() {
        return this.tfaEnabled;
    }
}
export class LoginModel {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }
}
