import * as React from "react";
import { EntityTable } from "../../../Common/Tables/EntityTable";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { ImageUpload } from "../../../Common/Fields/ImageUpload";
import { ServiceTable } from "./Services";
import { OverviewPage } from "../../../Common/Pages/OverviewPage";
import { ServiceRelationConfiguration } from "../Settings/ServiceRelationConfiguration";
import { TableType } from "../../../../Services/TableStateManager";
export class ServiceCategoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
        this.overviewData = {
            tabs: [
                {
                    label: 'Configuration',
                    value: 0,
                }
            ],
            breadCrumbs: ['Service Categories Table'],
            pages: [
                (data) => (React.createElement(ServiceTable, { serviceCategoryId: data }))
            ]
        };
        this.tableProps = {
            type: TableType.Services,
            apiController: "ServiceCategory",
            tableTitle: "Service Categories",
            overviewComponent: new OverviewPage(this.overviewData),
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Description', field: 'description' },
                {
                    title: "Icon", field: 'imageData', cellStyle: { maxWidth: 250, textOverflow: "ellipsis", overflow: "hidden" }, editComponent: props => React.createElement(ImageUpload, { onChange: props.onChange }),
                },
                {
                    title: 'Service Type',
                    field: 'type',
                    lookup: {
                        0: 'Default',
                        1: "Detailing",
                        2: "Haircuts",
                        3: "Shaves"
                    }
                }
            ],
            actions: [
                {
                    icon: 'settings',
                    tooltip: 'Configure Service Relations',
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                        this.setState({
                            configurePageOpen: true,
                        });
                    }
                }
            ],
            detailPanelActions: [
                {
                    icon: 'visibility',
                    openIcon: 'visibility_off',
                    tooltip: 'Preview Icon',
                    render: rowData => {
                        return (React.createElement("img", { className: "visitor-queue-icon", src: `/api/v1/serviceCategory/view/${rowData.id}`, alt: "Marketing Content" }));
                    }
                }
            ]
        };
        this.state = {
            configurePageOpen: false
        };
    }
    render() {
        if (this.state.configurePageOpen) {
            return React.createElement(ServiceRelationConfiguration, { onBackClick: () => this.setState({ configurePageOpen: false }) });
        }
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(EntityTable, Object.assign({}, this.tableProps))));
    }
}
