import * as React from "react";
import { EntityTable } from '../../../Common/Tables/EntityTable';
import { ImageUpload } from "../../../Common/Fields/ImageUpload";
import { PromotionViewer } from "../../../Display/Promotion/Promotion";
import { TableType } from "../../../../Services/TableStateManager";
export function PromotionTable() {
    var tableProps = {
        type: TableType.Promotions,
        apiController: "promotion",
        tableTitle: "Promotions",
        editableRegion: true,
        columns: [
            { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            { title: 'Type', field: 'displayType', type: 'numeric', lookup: { 0: 'Digital Sign', 1: 'Wallboard', 2: 'Check-in Kiosk' } },
            {
                title: "Content", field: 'contentUrl', cellStyle: { maxWidth: 250, textOverflow: "ellipsis", overflow: "hidden" }, editComponent: props => React.createElement(ImageUpload, { onChange: props.onChange })
            },
            { title: 'Region', field: 'region.name', editable: 'never' }
        ],
        actions: [],
        detailPanelActions: [
            {
                icon: 'visibility',
                openIcon: 'visibility_off',
                tooltip: 'Preview Promotion',
                render: rowData => {
                    return (React.createElement(PromotionViewer, { id: rowData.id }));
                }
            }
        ]
    };
    return (React.createElement(EntityTable, Object.assign({}, tableProps)));
}
