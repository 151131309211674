import * as React from "react";
import { Promotion } from "./Promotion";
import { LoadingSpinner } from "../../Common/Pages/LoadingSpinner";
export class PromotionQueue extends React.Component {
    constructor(props) {
        super(props);
        this.intervalId = undefined;
        this.state = {
            currentIndex: 0
        };
    }
    updatePage() {
        let nextPage = this.state.currentIndex + 1;
        let numPages = this.props.promotions.length || 0;
        if (nextPage >= numPages) {
            this.props.refreshData();
            this.setState({
                currentIndex: 0
            });
        }
        else {
            this.setState({ currentIndex: nextPage });
        }
    }
    componentWillMount() {
        if (this.props.changeInterval)
            this.intervalId = window.setInterval(() => this.updatePage(), this.props.changeInterval);
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
    render() {
        if (!this.state)
            return React.createElement(LoadingSpinner, null);
        return (React.createElement(Promotion, Object.assign({}, this.props.promotions[this.state.currentIndex])));
    }
}
