import * as React from "react";
import { EntityTable } from "../../../Common/Tables/EntityTable";
import { OverviewPage } from "../../../Common/Pages/OverviewPage";
import { DisplayConfiguration } from "../Overviews/Display/Configuration";
import { TableType } from "../../../../Services/TableStateManager";
export function DisplayTable() {
    var overviewData = {
        tabs: [
            {
                label: 'Configuration',
                value: 0,
            }
        ],
        breadCrumbs: ['Displays Table'],
        pages: [
            (data) => (React.createElement(DisplayConfiguration, { displayId: data }))
        ]
    };
    var overviewPage = new OverviewPage(overviewData);
    overviewPage.setState({ activeTab: overviewData.tabs[0].value });
    var tableProps = {
        type: TableType.Displays,
        apiController: "display",
        tableTitle: "Displays",
        overviewComponent: overviewPage,
        editableRegion: true,
        columns: [
            { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            { title: 'Type', field: 'type', type: 'numeric', lookup: { 0: 'Digital Sign', 1: 'Wallboard', 2: 'Check-in Kiosk' } },
            { title: 'Region', field: 'region.name', editable: 'never' }
        ],
        actions: []
    };
    return (React.createElement(EntityTable, Object.assign({}, tableProps)));
}
