import * as React from "react";
import { Grid, Button } from '@material-ui/core';
import { ApiUtils } from "../../../Services/ApiUtils";
export default class KioskError extends React.Component {
    render() {
        var errorID = uuidv4();
        if (this.props.error) {
            ApiUtils.error({
                events: [{
                        Timestamp: new Date(),
                        Level: "Error",
                        MessageTemplate: 'Device: {DeviceId} in Shop: {ShopId}, errored on Step: {KioskStep}. User clicked the Error button. Error ID: {ErrorId}',
                        Properties: {
                            ErrorId: errorID,
                            ShopId: this.props.error.kioskData.shopId,
                            KioskStep: this.props.error.kioskData.step,
                            DeviceId: this.props.error.kioskData.deviceId
                        }
                    }]
            });
        }
        console.log(this.props.error);
        return (React.createElement(Grid, { container: true, justify: "center", alignContent: "center", style: { minHeight: 350, width: "50%", margin: "auto", borderRadius: 25, textAlign: "center", backgroundColor: 'rgba(255, 255, 255, 0.8)' } },
            React.createElement(Grid, { item: true, className: "tg-kiosk-logo-container", xs: 10 },
                React.createElement("h1", { style: { color: "#BF0D3E", fontFamily: "Big Noodle Titling" } }, "Something went wrong"),
                React.createElement("div", { style: { color: "#111" } }, this.props.message || "We apologize for any inconvience this may have caused. Please show the following message to one of our associates."),
                React.createElement("div", { style: { color: "#111", marginTop: 15 } }, this.props.error.error),
                React.createElement("div", { style: { color: "#555", fontSize: 16 } },
                    React.createElement("span", null, this.props.error.errorInfo),
                    React.createElement("p", null,
                        "Error ID: ",
                        errorID),
                    React.createElement("p", null, new Date().toUTCString()))),
            React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15, marginBottom: 20 } },
                React.createElement("div", null,
                    React.createElement(Button, { onClick: () => this.props.onError }, "Close")))));
    }
}
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
