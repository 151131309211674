import * as React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { Typography, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
export class OverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
                MuiSnackbarContent: {
                    root: {
                        backgroundColor: '#1E1E1E',
                        color: '#fff'
                    }
                }
            }
        });
        this.handleChange = (event, value) => {
            this.setState({ activeTab: value });
        };
        this.state = {
            activeTab: 0,
        };
    }
    render() {
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(Grid, { spacing: 6 },
                React.createElement(Paper, null,
                    React.createElement(Tabs, { value: this.state.activeTab || 0, onChange: this.handleChange, variant: "fullWidth", indicatorColor: "primary", textColor: "secondary" }, this.props.tabs.map((t) => React.createElement(Tab, Object.assign({}, t))))),
                this.props.tabs.map((t) => this.state.activeTab == t.value &&
                    React.createElement(Typography, { style: { height: 500 } }, this.props.pages[t.value].apply(this, [this.props.recordID || 0]))))));
    }
}
