import * as React from 'react';
import { Grid, FormControl, TextField, Button, FormGroup } from '@material-ui/core';
import { ApiUtils } from '../../../Services/ApiUtils';
import { KioskUserProfile } from './KioskUser';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from "react-simple-keyboard";
export class AddGuestForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            isLocalAccount: true,
            tgUserPhoneNumber: "",
            tgAccountUsers: [],
            displayKeyboard: false
        };
    }
    onFirstNameChange(event) {
        this.setState({
            firstName: event.target.value
        });
    }
    onLastNameChange(event) {
        this.setState({
            lastName: event.target.value
        });
    }
    onToggleAccountType() {
        this.setState({
            isLocalAccount: !this.state.isLocalAccount
        });
    }
    onPhoneNumberChange(event) {
        this.setState({
            tgUserPhoneNumber: event.target.value
        });
    }
    onKeyboardPress(input) {
        this.setState({
            tgUserPhoneNumber: input
        });
    }
    renderKeyboard() {
        if (this.state.displayKeyboard) {
            return (React.createElement("div", null,
                React.createElement(Keyboard, { keyboardRef: r => (this.keyboard = r), baseClass: "tg-mobile-keyboard", onChange: (input) => this.onKeyboardPress(input), layout: { default: ["1 2 3", "4 5 6", "7 8 9", '* 0 {bksp}'] } })));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    }
    localGuestForm() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 5, justify: "center", style: { backgroundColor: "transparent" } },
                React.createElement(FormControl, { fullWidth: true },
                    React.createElement(TextField, { fullWidth: true, id: "firstName", name: "firstName", variant: "outlined", placeholder: "First Name", value: this.state.firstName, onChange: (event) => this.onFirstNameChange(event) }))),
            React.createElement(Grid, { item: true, xs: 5, justify: "center", style: { backgroundColor: "transparent" } },
                React.createElement(FormControl, { fullWidth: true },
                    React.createElement(TextField, { fullWidth: true, id: "lastName", name: "lastName", variant: "outlined", placeholder: "Last Name", value: this.state.lastName, onChange: (event) => this.onLastNameChange(event) }))),
            React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { backgroundColor: "transparent", marginTop: 25 } },
                React.createElement(Button, { color: "secondary", variant: "contained", onClick: () => this.props.onSubmit(this.state.firstName, this.state.lastName) }, "Confirm"))));
    }
    lookupUser() {
        if (!this.state.tgUserPhoneNumber) {
            return;
        }
        ApiUtils.get(`kiosk/GetAccountsForPhoneNumber/${this.state.tgUserPhoneNumber}`).then(r => {
            if (r.data) {
                this.setState({
                    tgAccountUsers: r.data,
                    Error: r.data.length <= 0 ? "No Accounts Found." : ""
                });
            }
        }, e => {
            this.setState({
                Error: "Unable to process the request, please ensure a valid phone number was entered."
            });
        });
    }
    selectExternalUser(user) {
        this.setState({
            tgSelectedUser: user
        });
    }
    confirmExternalUser() {
        if (this.props.onAddTGUser && this.state.tgSelectedUser) {
            this.props.onAddTGUser(this.state.tgSelectedUser);
        }
    }
    renderError() {
        return this.state.Error ?
            (React.createElement("div", null,
                React.createElement("div", { className: "tg-error-text" }, this.state.Error))) : (React.createElement(React.Fragment, null));
    }
    externalGuestForm() {
        if (this.state.tgAccountUsers && this.state.tgAccountUsers.length > 0) {
            return (React.createElement(React.Fragment, null,
                this.state.tgAccountUsers.map(u => (React.createElement(Grid, { item: true, xs: 2, style: { backgroundColor: "transparent", maxWidth: "unset" } },
                    React.createElement("span", { onClick: () => this.selectExternalUser(u), style: { color: u == this.state.tgSelectedUser ? '#BF0D3E' : '' } },
                        React.createElement(KioskUserProfile, { user: u, selected: u == this.state.tgSelectedUser }))))),
                React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "transparent" } },
                    React.createElement(Button, { style: { fontFamily: 'Gotham Book' }, color: "secondary", variant: "contained", onClick: () => this.confirmExternalUser() }, "Confirm"))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { backgroundColor: "transparent", padding: 20 } }, this.renderError()),
            React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { backgroundColor: "transparent" } },
                React.createElement(FormControl, { fullWidth: true },
                    React.createElement(TextField, { fullWidth: true, id: "phonenumber", name: "phonenumber", variant: "outlined", placeholder: "Phone Number", value: this.state.tgUserPhoneNumber, onTouchEnd: () => this.setState({ displayKeyboard: true }), onFocus: () => this.setState({ displayKeyboard: true }), onChange: (event) => this.onPhoneNumberChange(event) }))),
            React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { backgroundColor: "transparent", padding: 20 } },
                React.createElement(Button, { color: "secondary", variant: "contained", style: { fontFamily: 'Gotham Book' }, onClick: () => this.lookupUser() }, "Lookup")),
            React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { backgroundColor: "transparent", padding: this.props.isMobile ? 0 : 20, color: "black" } }, this.renderKeyboard())));
    }
    render() {
        return (React.createElement(Grid, { container: true, style: { margin: "auto", textAlign: "center", backgroundColor: "transparent" }, justify: "space-evenly", direction: "row", alignItems: "center" },
            React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "transparent" } },
                React.createElement(FormGroup, null)),
            this.externalGuestForm()));
    }
}
