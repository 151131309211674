import * as React from "react";
import { Grid, Button, TextField, FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import { ApiUtils } from "../../../Services/ApiUtils";
import Keyboard from "react-simple-keyboard";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { parsePhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
var RegistrationFormStep;
(function (RegistrationFormStep) {
    RegistrationFormStep[RegistrationFormStep["RegistrationCode"] = 0] = "RegistrationCode";
    RegistrationFormStep[RegistrationFormStep["RegisterUser"] = 1] = "RegisterUser";
})(RegistrationFormStep || (RegistrationFormStep = {}));
export class RegistrationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: this.props.phoneNumber || "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            displayKeyboard: false,
            registrationCode: "",
            step: RegistrationFormStep.RegisterUser,
            region: this.props.region.toLowerCase(),
        };
    }
    onFieldChange(value, fieldName) {
        var state = this.state;
        state[fieldName] = value;
        this.setState(state);
    }
    getRegistrationCode() {
        var data = {
            email: this.state.email
        };
        ApiUtils.post("kiosk/GetRegistrationCode", data).then(r => {
            if (r.status >= 200 && r.status <= 299) {
                this.setState({ step: RegistrationFormStep.RegisterUser });
            }
        });
    }
    validatePhoneNumber(number, language) {
        const validLanguages = ['us', 'ca', 'nz', 'au'];
        if (!validLanguages.includes(language)) {
            return;
        }
        const parsedNumber = parsePhoneNumber(number, language.toUpperCase());
        if (!parsedNumber || !(parsedNumber === null || parsedNumber === void 0 ? void 0 : parsedNumber.isValid())) {
            return;
        }
        const fixedNumber = parsedNumber.format('NATIONAL');
        const formattedNumber = parsedNumber.format('E.164');
        if (fixedNumber !== number) {
            return formattedNumber;
        }
        return number;
    }
    validateForm() {
        var isValidPTN = this.validatePhoneNumber(this.state.phoneNumber, this.state.region);
        if (isValidPTN == undefined) {
            this.setState({
                error: {
                    Type: "Warning",
                    Message: "The number entered is not valid."
                }
            });
            return false;
        }
        if (!this.state.firstName || !this.state.lastName) {
            this.setState({
                error: {
                    Type: "Warning",
                    Message: "First and Last name are required"
                }
            });
            return false;
        }
        return true;
    }
    submitForm() {
        if (!this.validateForm()) {
            return;
        }
        const isValidPTN = this.validatePhoneNumber(this.state.phoneNumber, this.state.region);
        var apiPhoneNumber = this.state.phoneNumber;
        if (isValidPTN !== undefined) {
            apiPhoneNumber = isValidPTN.replace('+1', '').replace('+', '');
        }
        else {
            return;
        }
        var data = {
            registrationCode: this.state.registrationCode,
            phoneNumber: apiPhoneNumber,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password
        };
        ApiUtils.post(`kiosk/CreateUser`, data)
            .then(r => {
            if (r.status >= 200 && r.status <= 299) {
                var usr = {
                    id: r.data.id,
                    phoneNumber: data.phoneNumber,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    checkedIn: false
                };
                this.props.onRegister(usr);
            }
        });
    }
    setActiveField(fieldName) {
        if (fieldName !== this.state.activeField) {
            if (this.keyboard)
                this.keyboard.clearInput();
            this.setState({ activeField: fieldName });
        }
    }
    cancelForm() {
        this.props.onBackClick();
    }
    onKeyboardPress(input) {
        this.onFieldChange(input, this.state.activeField);
    }
    renderKeyboard() {
        // Keyboard is disabled for now.
        if (this.state.displayKeyboard) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Keyboard, { keyboardRef: r => (this.keyboard = r), onChange: (input) => this.onKeyboardPress(input) })));
        }
        return (React.createElement(React.Fragment, null));
    }
    renderButtons() {
        if (this.props.isMobile) {
            return (React.createElement("div", { style: { textAlign: "right", display: "flex", width: "100vw", justifyContent: "space-evenly", marginTop: "70px", marginLeft: "-12%" } },
                React.createElement("div", { className: "tg-back-button", onClick: (event) => this.props.onBackClick(), style: { position: "unset", display: "flex", width: "150px" } },
                    React.createElement("div", { className: "tg-button-arrow-container", style: { position: "relative" } },
                        React.createElement(ArrowBackIosIcon, { className: "tg-back-button-arrow", style: { position: "unset" } })),
                    React.createElement("div", { className: "tg-back-button-text", style: { position: "unset" } }, "Back")),
                React.createElement("div", { className: "tg-next-button-mobile", onClick: (event) => this.submitForm() },
                    React.createElement("div", { style: { width: "100%", marginTop: -5, letterSpacing: 5, fontSize: 45, fontFamily: "Big Noodle Titling" } }, "Next"),
                    React.createElement("div", { style: { width: "100%", textAlign: "center", textTransform: "uppercase", fontFamily: 'Gotham Book' } }, "Choose Services"))));
        }
        else {
            return (React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { display: "flex", flexDirection: "row", minWidth: 200 } },
                React.createElement("div", { className: "tg-back-button", onClick: (event) => this.props.onBackClick() },
                    React.createElement("div", { className: "tg-button-arrow-container" },
                        React.createElement(ArrowBackIosIcon, { className: "tg-back-button-arrow" })),
                    React.createElement("div", { className: "tg-back-button-text" }, "Back")),
                React.createElement("div", { className: "tg-next-button", onClick: (event) => this.submitForm() },
                    React.createElement("div", { style: { width: "100%", letterSpacing: 5, fontSize: 45, fontFamily: "Big Noodle Titling" } }, "Next"),
                    React.createElement("div", { style: { width: "100%", textAlign: "center", textTransform: "uppercase" } }, "Choose Services"))));
        }
    }
    renderError() {
        if (!this.state.error) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("div", { style: { paddingTop: 30 } },
            React.createElement("div", { style: { fontSize: 35, color: "#BF0D3E", fontFamily: "Big Noodle Titling" } }, this.state.error.Message)));
    }
    getStep() {
        if (this.state.step == RegistrationFormStep.RegisterUser) {
            const { phoneNumber } = this.state;
            const allowedCountries = ['ca', 'nz', 'us', 'au'];
            const onChangeInput = (value, country) => {
                this.setState({
                    phoneNumber: value, region: country.countryCode
                });
            };
            return (React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, style: { height: "50%", width: this.props.isMobile ? "80%" : "50%", margin: "auto", textAlign: "center" }, justify: "center", direction: "row", alignItems: "center" },
                    React.createElement(Grid, { item: true, xs: 12, justify: "center", className: this.props.disable ? "e-disabled" : "" },
                        this.renderError(),
                        React.createElement("div", { style: { display: "inline-block", verticalAlign: "top", color: "black", paddingBottom: 15, width: "100%" } },
                            React.createElement(PhoneInput, { country: this.state.region, value: phoneNumber, onlyCountries: allowedCountries, onChange: onChangeInput, countryCodeEditable: false, disableCountryGuess: true, inputProps: { name: 'phone', required: true, autoFocus: true }, inputStyle: { width: "100%" } }))),
                    React.createElement(Grid, { item: true, xs: this.props.isMobile ? 12 : 6, justify: "center" },
                        React.createElement(FormControl, { fullWidth: true },
                            React.createElement(TextField, { id: "fName", name: "fName", variant: "outlined", onFocus: () => this.setActiveField("firstName"), placeholder: "First Name", className: "wallboard-config-input", required: true, value: this.state.firstName, onChange: (event) => this.onFieldChange(event.target.value, "firstName") }))),
                    React.createElement(Grid, { item: true, xs: this.props.isMobile ? 12 : 6, justify: "center" },
                        React.createElement(FormControl, { fullWidth: true, style: { paddingLeft: this.props.isMobile ? 0 : 15 } },
                            React.createElement(TextField, { fullWidth: true, id: "lName", name: "lName", variant: "outlined", placeholder: "Last Name", className: "wallboard-config-input", value: this.state.lastName, required: true, onFocus: () => this.setActiveField("lastName"), onChange: (event) => this.onFieldChange(event.target.value, "lastName") }))),
                    React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { marginTop: this.props.isMobile ? 5 : 0 } },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { value: "checkedA" }), label: "I agree to receive promotional content" }),
                        this.renderButtons()),
                    React.createElement("div", { style: { color: "black", maxWidth: "90%", margin: "auto", marginTop: 25 } }, this.renderKeyboard()))));
        }
        // Registration 
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, style: { height: "50%", width: "50%", margin: "auto", textAlign: "center" }, justify: "center", direction: "row", alignItems: "center" },
                    React.createElement(Grid, { item: true, xs: 12, justify: "center" },
                        React.createElement(FormControl, { fullWidth: true },
                            React.createElement(TextField, { fullWidth: true, id: "email", name: "email", variant: "outlined", placeholder: "Email", className: "wallboard-config-input", value: this.state.email, onFocus: () => this.setActiveField("email"), onChange: (event) => this.onFieldChange(event.target.value, "email") }))),
                    React.createElement(Grid, { item: true, xs: 12, justify: "center" },
                        React.createElement(Button, { style: { margin: "auto", marginLeft: "15px" }, onClick: (event) => this.getRegistrationCode(), variant: "contained", size: "large", color: "secondary" }, "Get Registration Code")))));
        }
    }
    render() {
        return (React.createElement(React.Fragment, null, this.getStep()));
    }
}
