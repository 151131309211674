import * as React from "react";
import { EntityTable } from "../../../Common/Tables/EntityTable";
import { TableType } from "../../../../Services/TableStateManager";
export function UserTable() {
    var tableProps = {
        type: TableType.Users,
        apiController: "user",
        tableTitle: "Users",
        columns: [
            { title: 'Id', field: 'id', hidden: true, editable: 'never' },
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email', editable: 'onAdd' },
            { title: 'Description', field: 'description' }
        ],
        actions: []
    };
    return (React.createElement(EntityTable, Object.assign({}, tableProps)));
}
