import * as React from 'react';
import { Paper, TextField, FormControl, FormLabel, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import { ForeignKeyDropdown } from '../../../../Common/Fields/ForeignKeyDropdown';
export function CheckinKioskConfigurationForm(props) {
    var config = (typeof (props.configValues) == "string") ? JSON.parse(props.configValues) : props.configValues;
    return (React.createElement(Paper, { style: { padding: 25 } },
        React.createElement("form", { onSubmit: props.onSave },
            React.createElement(Grid, { container: true, spacing: 4 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { alignItems: "center", style: { textAlign: "center" }, container: true, justify: "center", spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "fontColor", name: "fontColor", variant: "outlined", label: "Font color", value: config.fontColor, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "backgroundColor", name: "backgroundColor", variant: "outlined", label: "Background color", value: config.backgroundColor, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(FormControlLabel, { control: React.createElement(ForeignKeyDropdown, { fieldName: 'backgroundImage', onChange: props.onChange, nullable: true, value: config.backgroundImage, apiController: "Asset", apiAction: "BackgroundImages", returnEvent: true }), label: "Background Image", labelPlacement: "top" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "loginBackgroundColor", name: "loginBackgroundColor", variant: "outlined", label: "Login Background color", value: config.loginBackgroundColor, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(FormControlLabel, { control: React.createElement(ForeignKeyDropdown, { fieldName: 'loginBackgroundImage', onChange: props.onChange, nullable: true, value: config.loginBackgroundImage, apiController: "Asset", apiAction: "BackgroundImages", returnEvent: true }), label: "Login Image", labelPlacement: "top" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "loginWelcomeText", name: "loginWelcomeText", variant: "outlined", label: "Login Welcome Text", value: config.loginWelcomeText, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(FormControlLabel, { control: React.createElement(ForeignKeyDropdown, { fieldName: 'completionBackgroundImage', onChange: props.onChange, nullable: true, value: config.completionBackgroundImage, apiController: "Asset", apiAction: "BackgroundImages", returnEvent: true }), label: "Completion Background Image", labelPlacement: "top" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(FormControlLabel, { control: React.createElement(ForeignKeyDropdown, { fieldName: 'completionImage', onChange: props.onChange, nullable: true, value: config.completionImage, apiController: "Asset", apiAction: "BackgroundImages", returnEvent: true }), label: "Completion Image", labelPlacement: "top" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "completionDuration", name: "completionDuration", variant: "outlined", label: "Final screen duration (S)", title: "The number of seconds the successful checkin screen will be shown.", value: config.completionDuration, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "promotionalDisclaimerText", name: "promotionalDisclaimerText", variant: "outlined", label: "Promotional Disclaimer Text", value: config.promotionalDisclaimerText, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "connectionErrorMessage", name: "connectionErrorMessage", variant: "outlined", label: "Connection Error Message", value: config.connectionErrorMessage, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "onErrorMessage", name: "onErrorMessage", variant: "outlined", label: "Error window text", title: "Text to be displayed on the error page.", value: config.onErrorMessage, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "closedShopText", name: "closedShopText", variant: "outlined", label: "Closed Shop Text", title: "Text to be displayed on kiosk of shop while closed", value: config.closedShopText, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 4, sm: 4, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "idleWindowTitle", name: "idleWindowTitle", variant: "outlined", label: "Idle Window Title", title: "Title to be displayed on the idle popup.", value: config.idleWindowTitle, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 4, sm: 4, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "idleWindowText", name: "idleWindowText", variant: "outlined", label: "Idle Window Text", title: "Text to be displayed on the idle popup.", value: config.idleWindowText, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 2, sm: 2, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "idleThreshold", name: "idleThreshold", variant: "outlined", label: "Idle Threshold (S)", title: "Number of seconds before displaying the idle popup.", value: config.idleThreshold, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 2, sm: 2, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "abandonSessionThreshold", name: "abandonSessionThreshold", variant: "outlined", label: "Abandon Session Threshold (S)", title: "Number of seconds before abandoning the session due to idle activity.", value: config.abandonSessionThreshold, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 2, sm: 2, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "waitTimeOffset", name: "waitTimeOffset", variant: "outlined", label: "Wait Time Offset (S)", value: config.waitTimeOffset, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 2, sm: 2, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(FormLabel, null, "Display Wait Time"),
                                React.createElement(Checkbox, { checked: config.displayWaitTime, id: "displayWaitTime", name: "displayWaitTime", value: config.displayWaitTime, onChange: props.onChange }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12, justify: "center" },
                            React.createElement(FormControl, null,
                                React.createElement(Button, { style: { maxWidth: "80px", margin: "auto" }, variant: "contained", size: "small", color: "primary", onClick: props.onSave },
                                    React.createElement(SaveIcon, null),
                                    "Save")))))))));
}
