import * as React from "react";
import { floor } from "lodash";
function formatTimeString(totalMinutes) {
    if (totalMinutes < 60) {
        return `${totalMinutes}m`;
    }
    let hours = floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
}
export function VisitorTableCell(props) {
    let visitor = props.visitor;
    let waitTimeText = (visitor.soonestWaitTime == visitor.longestWaitTime) ? `${formatTimeString(visitor.longestWaitTime)}` : `${formatTimeString(visitor.soonestWaitTime)} - ${formatTimeString(visitor.longestWaitTime)}`;
    return (React.createElement("div", { className: "col-12 visitor-queue-table-cell" },
        React.createElement("div", { className: "col-1" },
            React.createElement("div", { className: "visitor-queue-position" },
                visitor.id,
                ".")),
        React.createElement("div", { className: "col-5 d-flex visitor-queue-info" },
            React.createElement("div", { className: "col-12 visitor-queue-name" }, visitor.name),
            React.createElement("div", { className: "col-12 visitor-queue-wait" }, waitTimeText)),
        React.createElement("div", { className: "col-6 d-flex" },
            React.createElement("div", { className: "col visitor-queue-barber" },
                "Barber: ",
                visitor.barber),
            React.createElement("div", { className: "col visitor-queue-icon-container" }, visitor.services.map(x => React.createElement("span", { className: "visitor-queue-icon" },
                React.createElement("img", { src: "/api/v1/serviceCategory/view/" + x }))))),
        React.createElement("div", { className: "visitor-queue-footer" })));
}
