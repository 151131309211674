import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Select, MenuItem, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { ApiUtils } from "../../../Services/ApiUtils";
import { LoadingSpinner } from "../Pages/LoadingSpinner";
export class ShortcutsShopWindow extends React.Component {
    constructor() {
        super(...arguments);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
        this.handleChange = (event) => {
            this.setState({
                value: event.target.value,
            });
        };
        this.handleSave = (event) => {
            let shop = this.state.shops.filter(s => s.shortcutsId == this.state.value);
            if (shop.length > 0) {
                ApiUtils.post('shop/create', shop[0]).then(r => {
                    this.props.onSave.apply(null);
                    this.setState({
                        value: 0
                    });
                });
            }
        };
    }
    componentWillMount() {
        ApiUtils.get('shop/FromShortcuts').then(r => {
            this.setState({
                shops: r.data
            });
        });
    }
    render() {
        if (!this.state || !this.state.shops) {
            return React.createElement(LoadingSpinner, null);
        }
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(Dialog, { open: this.props.open, onClose: this.props.onClose },
                React.createElement(DialogTitle, null, "Add from Shortcuts"),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, null, "Choose from the list of Shops provided by Shortcuts."),
                    React.createElement(Select, { onChange: this.handleChange, value: this.state.value, fullWidth: true },
                        React.createElement(MenuItem, { key: 0, value: 0 }, "None "),
                        this.state.shops.map(s => React.createElement(MenuItem, { key: s.shortcutsId, value: s.shortcutsId }, s.name)))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: this.props.onClose, color: "secondary" }, "Cancel"),
                    React.createElement(Button, { onClick: this.handleSave, color: "primary" }, "Save")))));
    }
}
