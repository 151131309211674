import * as React from "react";
import { TableType } from "../../../../Services/TableStateManager";
import { ImageUpload } from "../../../Common/Fields/ImageUpload";
import { EntityTable } from "../../../Common/Tables/EntityTable";
export class MobilePromotionsTable extends React.Component {
    constructor() {
        super(...arguments);
        this.tableProps = {
            type: TableType.MobilePromotions,
            apiController: "mobilepromotion",
            tableTitle: "Mobile Promotions",
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Description', field: 'description' },
                { title: 'Type', field: 'displayType', type: 'numeric', lookup: { 0: 'Retail', 1: 'Promotional' } },
                { title: 'Url', field: 'url' },
                {
                    title: "Content", field: 'contentUrl', cellStyle: { maxWidth: 250, textOverflow: "ellipsis", overflow: "hidden" }, editComponent: props => React.createElement(ImageUpload, { onChange: props.onChange })
                },
            ],
            actions: [],
            detailPanelActions: [
                {
                    icon: 'visibility',
                    openIcon: 'visibility_off',
                    tooltip: 'Preview',
                    render: rowData => {
                        return (React.createElement("img", { className: "mobile-promotion-preview", src: `/api/v1/mobilepromotion/view/${rowData.id}`, alt: `${rowData.Name}` }));
                    }
                }
            ]
        };
    }
    render() {
        return (React.createElement(EntityTable, Object.assign({}, this.tableProps)));
    }
}
