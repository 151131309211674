import * as React from "react";
import { createMuiTheme } from "@material-ui/core";
import { MuiThemeProvider, Grid } from '@material-ui/core';
export default class AuthError extends React.Component {
    constructor() {
        super(...arguments);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
    }
    render() {
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement("main", null,
                React.createElement(Grid, { style: { width: "75%", margin: "auto" }, container: true, justify: "space-between", direction: "row", alignItems: "center" },
                    React.createElement(Grid, { item: true, xs: 12, justify: "center" },
                        React.createElement("div", null, "An error occured when trying to authenticate."),
                        React.createElement("div", { style: { fontSize: 16 } }, this.props.ErrorMessage))))));
    }
}
