import * as React from "react";
import { VisitorTableCell } from "../VisitorQueueTable/VisitorTableCell";
export function VisitorQueue(props) {
    document.title = props.pageModel.titleText;
    return (React.createElement("div", { className: "visitor-queue-container", style: { color: props.configuration.fontColor, backgroundColor: props.configuration.backgroundColor, backgroundImage: props.configuration.backgroundImage ? `url('/api/v1/asset/view/${props.configuration.backgroundImage}')` : '', backgroundSize: 'cover' } },
        React.createElement("div", { className: "col-12 visitor-queue-header" },
            React.createElement("div", { className: "visitor-queue-title-container" },
                React.createElement("div", { className: "title visitor-queue-title" },
                    React.createElement("span", { className: "title-text" }, props.pageModel.titleText)))),
        React.createElement(VisitorQueueTable, Object.assign({}, props))));
}
class VisitorQueueTable extends React.Component {
    render() {
        if (!this.props.pageModel.visitors || this.props.pageModel.visitors.length == 0)
            return (React.createElement("div", null));
        let visitorsPerPage = this.props.configuration.rowsPerPage * this.props.configuration.columnsPerPage;
        let startingVisitorIndex = (visitorsPerPage * this.props.currentPage) + 1;
        return this.props.configuration.layoutDirection
            ? this.VerticalVisitorQueue(startingVisitorIndex)
            : this.HorizontalVisitorQueue(startingVisitorIndex);
    }
    VerticalVisitorQueue(startingVisitorIndex) {
        if (startingVisitorIndex <= 0) {
            return;
        }
        let someColumns = [];
        for (let i = 0; i < this.props.configuration.columnsPerPage; i++) {
            someColumns.push(this.VerticalVisitorColumn(startingVisitorIndex, i));
        }
        return (React.createElement(React.Fragment, null,
            " ",
            someColumns,
            " "));
    }
    VerticalVisitorColumn(startingVisitorIndex, columnIndex) {
        let rowsPerPage = parseInt(this.props.configuration.rowsPerPage + "");
        let startingColumnIndex = columnIndex * rowsPerPage;
        let endingColumnIndex = startingColumnIndex + rowsPerPage - 1;
        let columnVisitorCounter = startingVisitorIndex + startingColumnIndex;
        let alignToTop = {
            verticalAlign: 'top'
        };
        return (React.createElement("div", { className: `col-${this.BootstrapColumnValue()}`, style: alignToTop }, this.props.pageModel.visitors.slice(startingColumnIndex, endingColumnIndex + 1).map(v => {
            let visitor = v;
            visitor.id = columnVisitorCounter++;
            return (React.createElement("div", { className: `visitor-queue-table-cell col-12` },
                React.createElement(VisitorTableCell, { visitor: visitor })));
        })));
    }
    HorizontalVisitorQueue(startingVisitorIndex) {
        return (React.createElement(React.Fragment, null, this.props.pageModel.visitors.map(v => {
            let visitor = v;
            visitor.id = startingVisitorIndex++;
            return (React.createElement("div", { className: `visitor-queue-table-cell col-${this.BootstrapColumnValue()}` },
                React.createElement(VisitorTableCell, { visitor: visitor })));
        })));
    }
    BootstrapColumnValue() {
        return (12 / Math.max(1, Math.min(12, this.props.configuration.columnsPerPage)));
    }
}
