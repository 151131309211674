import * as React from "react";
import { Grid, FormControl, Select, MenuItem, DialogTitle, IconButton, DialogContent } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ServiceSquareSimple } from "../CheckinKiosk/ServiceSquareSimple";
import { BarberSquareSimple } from "../CheckinKiosk/BarberSquareSimple";
import { Dialog } from "@material-ui/core";
import { UserSelector } from "../CheckinKiosk/UserSelector";
import { GroupCreator } from "../CheckinKiosk/GroupCreator";
import { ApiUtils } from "../../../Services/ApiUtils";
import Keyboard from "react-simple-keyboard";
import 'react-simple-keyboard/build/css/index.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
export class CheckinSummaryMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: props.user.phoneNumber,
            delayValue: 0,
            reminderValue: 10,
            guestModal: false,
            userModal: false,
            displayError: false,
        };
    }
    componentDidMount() {
        // POST to API customer information, get back a confirmation number
        //var services = this.props.services.map(s => { return { employeeId: this.props.barber.id, serviceId: s.id } })
        var services = this.props.services.map(s => { return { employeeId: this.props.barber.referenceId, serviceId: s.referenceId }; }); // TEMP: For testing
        var data = {
            User: this.props.user,
            services: services
        };
        ApiUtils.post(`kiosk/CreateWalkinForShop/${this.props.shopId}`, data).then(r => {
            if (r.status == 200) {
                var currentTime = new Date();
                var inServiceTime = new Date(r.data.in_service_site_datetime);
                var waitTime = r.data.waitTime;
                switch (true) {
                    case waitTime >= 50:
                        this.setState({ reminderValue: 35 });
                        break;
                    case waitTime >= 40:
                        this.setState({ reminderValue: 25 });
                        break;
                    case waitTime >= 25:
                        this.setState({ reminderValue: 15 });
                        break;
                    case waitTime >= 15:
                        this.setState({ reminderValue: 5 });
                        break;
                    default:
                        this.setState({ reminderValue: 5 });
                        break;
                }
                if (r.data.href) {
                    this.setState({
                        visitId: r.data.href.split('/').splice(-1)[0],
                        waitTime: waitTime,
                        // Setting the initial default notification timing
                        reminderValue: waitTime < 10 ? (waitTime < 5 ? 0 : 5) : this.state.reminderValue
                    });
                }
            }
            else {
                this.setState({ displayError: true });
            }
        }, e => {
            this.setState({ displayError: true });
        });
    }
    onWaitDelayChange(event) {
        this.setState({
            delayValue: event.target.value
        });
    }
    onReminderChanger(event) {
        this.setState({
            reminderValue: event.target.value
        });
    }
    onPhoneNumberChange(event) {
        this.setState({
            phoneNumber: event.target.value
        });
    }
    onComplete() {
        // POST confirmation number
        var data = {
            visitId: this.state.visitId,
            smsNumber: this.state.phoneNumber,
            notificationMinutes: this.state.reminderValue,
            delay: this.state.delayValue
        };
        ApiUtils.post(`kiosk/ConfirmWalkin/${this.props.shopId}`, data).then(r => {
            if (r.status == 200) {
                var availableUsers = this.props.availableUsers.filter(u => u != this.props.user && this.props.checkedInUsers.filter(cu => cu == u).length == 0);
                if (availableUsers.length == 0)
                    this.props.onComplete();
                else
                    this.setState({ userModal: true });
            }
            else {
                // Handle error
            }
        });
    }
    onAddGuest() {
        this.setState({
            guestModal: true
        });
    }
    onBack() {
        // Cancel appointment and go back
        var data = {
            visitId: this.state.visitId
        };
        ApiUtils.post(`kiosk/CancelWalkin/${this.props.shopId}`, data).then(r => {
            if (r.status == 200) {
                this.props.onBack();
            }
            // Else we should display an error message to the user.
        });
    }
    guestDialog() {
        var availableUsers = this.props.availableUsers.filter(u => u != this.props.user && this.props.checkedInUsers.filter(cu => cu == u).length == 0);
        return (React.createElement(Dialog, { open: this.state.guestModal, maxWidth: 'lg', fullWidth: true, style: { textAlign: 'center' } },
            React.createElement(DialogTitle, null,
                React.createElement(IconButton, { "aria-label": "close", onClick: () => this.setState({ guestModal: false }), style: { position: "absolute", right: 0, top: 0 } },
                    React.createElement(CloseIcon, null)),
                React.createElement(DialogContent, null,
                    React.createElement(GroupCreator, { users: availableUsers, onCancelClick: () => this.setState({ guestModal: false }), onConfirmClick: (group) => this.props.onConfirm(group[0]), lightTheme: true, isMobile: this.props.isMobile })))));
    }
    userDialog() {
        var availableUsers = this.props.availableUsers.filter(u => u != this.props.user && this.props.checkedInUsers.filter(cu => cu == u).length == 0);
        return (React.createElement(Dialog, { open: this.state.userModal, maxWidth: 'lg', fullWidth: true, style: { textAlign: 'center' } },
            React.createElement(DialogTitle, null,
                React.createElement(IconButton, { "aria-label": "close", onClick: () => this.setState({ userModal: false }), style: { position: "absolute", right: 0, top: 0 } },
                    React.createElement(CloseIcon, null)),
                React.createElement(DialogContent, null,
                    React.createElement(UserSelector, { isMobile: this.props.isMobile, users: availableUsers, onConfirm: (user) => this.props.onConfirm(user), onComplete: this.props.onComplete })))));
    }
    renderConfirmDialog() {
        if (this.state.guestModal)
            return this.guestDialog();
        if (this.state.userModal)
            return this.userDialog();
        return (React.createElement(React.Fragment, null));
    }
    renderServices() {
        var containerStyles = {
            maxHeight: 600,
            width: "100vw",
            overflowX: "scroll",
        };
        var servicesStyles = {
            display: "flex",
            width: "95%"
        };
        return (React.createElement(Grid, { item: true, xs: 12, style: { textAlign: "center" } },
            React.createElement("div", { className: "tg-barbers-category", style: { padding: 10, fontSize: 39, textAlign: 'left', borderBottom: '5px #BF0D3E solid', marginBottom: 30 } }, "Selected Services"),
            (this.props.services.length > 3) ? React.createElement("div", { className: "tg-services-overlay-mobile" }) : React.createElement(React.Fragment, null),
            React.createElement("div", { className: "tg-services-container", style: containerStyles },
                React.createElement("div", { className: "tg-services-mobile tg-services__haircuts", style: servicesStyles }, this.props.services.map(s => React.createElement(ServiceSquareSimple, { shopId: this.props.shopId, service: s, onSelection: () => { return; }, disabled: false, widthOveride: "115px", selectOveride: true }))))));
    }
    renderWaitTime() {
        if (this.state.waitTime) {
            return `${this.state.waitTime} - ${this.state.waitTime + 5} mins`; //TODO: Need to properly calculate offset
        }
        else {
            return "Calculating...";
        }
    }
    renderBarber() {
        return (React.createElement(Grid, { item: true, xs: 12, style: { textAlign: "center" } },
            React.createElement("div", { style: { maxHeight: 246 } },
                React.createElement(BarberSquareSimple, { barber: this.props.barber, hideWaitTime: true, onSelection: (barber) => { return; }, overideSize: 115 }))));
    }
    renderError() {
        return (React.createElement(Dialog, { open: this.state.displayError },
            React.createElement(DialogTitle, null, "Error"),
            React.createElement(DialogContent, null, this.props.config.onErrorMessage)));
    }
    calculateReminderOptions() {
        var waitTime = this.state.waitTime || 0;
        var availableOptions = [];
        if (waitTime > 10)
            availableOptions.push(5);
        if (waitTime > 15)
            availableOptions.push(10);
        if (waitTime > 20)
            availableOptions.push(15);
        if (waitTime > 30)
            availableOptions.push(25);
        if (waitTime > 60)
            availableOptions.push(45);
        if (waitTime > 75)
            availableOptions.push(60);
        return availableOptions;
    }
    renderNotification() {
        return (React.createElement(Grid, { item: true, xs: 12, style: { textAlign: "left", marginTop: -20 } },
            React.createElement("div", null, this.state.waitTime != 0 && this.state.waitTime != undefined ? "We'll text you a reminder closer to your estimated check-in." : "Not Available")));
    }
    renderReminderTimes() {
        var waitTime = this.state.waitTime || 0;
        if (waitTime >= 5) {
            var reminderTimes = this.calculateReminderOptions();
            return (React.createElement(FormControl, { style: { padding: 10, float: "left" } },
                React.createElement(Select, { id: "reminder", name: "reminder", variant: "filled", className: "tg-summary-input", value: this.state.reminderValue, onChange: (event) => this.onReminderChanger(event), label: "Minutes before estimated service start time" },
                    React.createElement(MenuItem, { value: 0 }, "No reminder"),
                    reminderTimes.map(t => (React.createElement(MenuItem, { value: t }, t)))),
                React.createElement("span", { style: { float: "left", marginTop: 13 } }, "minutes before estimated service start time")));
        }
    }
    renderButtons() {
        return (React.createElement("div", { id: "testMobile", style: { textAlign: "right", display: "flex", width: "100vw", justifyContent: "space-evenly", marginTop: 30 } },
            React.createElement("div", { className: "tg-back-button", onClick: (event) => this.onBack(), style: { position: "unset", display: "flex", width: "125px" } },
                React.createElement("div", { className: "tg-button-arrow-container", style: { position: "relative" } },
                    React.createElement(ArrowBackIosIcon, { className: "tg-back-button-arrow", style: { position: "unset" } })),
                React.createElement("div", { className: "tg-back-button-text", style: { position: "unset" } }, "Back")),
            React.createElement("div", { className: "tg-next-button-mobile", onClick: (event) => this.onComplete(), style: { position: "relative" } },
                React.createElement("div", { style: { width: "100%", marginTop: -5, marginBottom: -5, letterSpacing: 5, fontSize: 40, fontFamily: "Big Noodle Titling" } }, "Complete"),
                React.createElement("div", { style: { width: "100%", textAlign: "center", textTransform: "uppercase", fontFamily: 'Gotham Book' } }, "Check-in"))));
    }
    onKeyboardPress(input) {
        this.setState({
            phoneNumber: input
        });
    }
    renderKeyboard() {
        // Keyboard is disabled for now.
        if (this.state.displayKeyboard) {
            return (React.createElement("div", null,
                React.createElement(Keyboard, { keyboardRef: r => (this.keyboard = r), onChange: (input) => this.onKeyboardPress(input), baseClass: "tg-summary-keyboard", layout: { default: ["1 2 3", "4 5 6", "7 8 9", '{bksp} 0'] } })));
        }
    }
    render() {
        var categoryStyles = {
            fontSize: 40,
            fontFamily: 'Big Noodle Titling',
            textAlign: 'left',
            marginBottom: 30,
            borderBottom: '5px #BF0D3E solid'
        };
        var containerStyles = {
            maxHeight: 600,
            width: "100vw",
            overflowX: "scroll",
        };
        var servicesStyles = {
            display: "flex",
            width: "95%"
        };
        var notificationStyles = {
            maxHeight: 600,
            width: "100vw",
        };
        return (React.createElement("div", null,
            React.createElement(Grid, { container: true, style: { height: "50%", textAlign: "center", padding: "10px 20px", overflowX: "hidden" }, justify: "space-between", direction: "column", alignItems: "flex-start" },
                React.createElement(Grid, { className: "tg-category-container" },
                    React.createElement("div", { className: "tg-services-category", style: categoryStyles }, "Selected Services"),
                    (this.props.services.length > 3) ? React.createElement("div", { className: "tg-services-overlay-mobile" }) : React.createElement(React.Fragment, null),
                    React.createElement("div", { className: "tg-services-container", style: containerStyles },
                        React.createElement("div", { className: "tg-services-mobile", style: servicesStyles }, this.props.services.map(s => React.createElement(ServiceSquareSimple, { shopId: this.props.shopId, service: s, onSelection: () => { return; }, disabled: false, widthOveride: "115px", selectOveride: true }))))),
                React.createElement(Grid, { className: "tg-category-container" },
                    React.createElement("div", { className: "tg-services-category", style: categoryStyles }, "Barber"),
                    React.createElement("div", { className: "tg-services-container", style: containerStyles },
                        React.createElement("div", { className: "tg-services-mobile", style: servicesStyles }, this.renderBarber()))),
                React.createElement(Grid, { className: "tg-category-container" },
                    React.createElement("div", { className: "tg-services-category", style: categoryStyles }, "Notification"),
                    React.createElement("div", { className: "tg-services-container", style: notificationStyles },
                        React.createElement("div", { className: "tg-services-mobile", style: servicesStyles }, this.renderNotification()))),
                React.createElement(Grid, { item: true, xs: 6, justify: "center" }),
                this.renderButtons(),
                React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { color: "black", position: "absolute", bottom: 0, width: "90%" } }, this.renderKeyboard()))));
    }
}
