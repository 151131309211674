import * as React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ApiUtils } from "../../../../Services/ApiUtils";
import { LoadingSpinner } from "../../../Common/Pages/LoadingSpinner";
import { Button, Checkbox } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
export class ServiceRelationConfiguration extends React.Component {
    componentWillMount() {
        this.getData();
    }
    getData() {
        ApiUtils.get(`Service/ServiceRelations`)
            .then(r => {
            var tData = {
                rows: r.data,
                columns: r.data,
            };
            this.setState({
                tableData: tData
            });
        });
    }
    onSave() {
        // Post data to server
        // trigger onBack.
        this.props.onBackClick();
    }
    handleChange(source, target) {
        var data = {
            SourceId: source.id,
            TargetId: target.id
        };
        var relationExists = source.relations.filter(r => r.id == target.id).length > 0;
        var action = `Service/${relationExists ? 'DeleteServiceRelation' : 'CreateServiceRelation'}`;
        // Add the Relation, or remove it if it already exists.
        ApiUtils.post(action, data).then(r => {
            //Reload data
            this.getData();
        });
    }
    render() {
        if (!this.state || !this.state.tableData) {
            return React.createElement(LoadingSpinner, null);
        }
        var rowCount = 0;
        return (React.createElement("div", null,
            React.createElement(Button, { onClick: () => this.props.onBackClick() },
                React.createElement(ArrowBack, null),
                " Service Categories Table"),
            React.createElement(TableContainer, { component: Paper, style: { maxHeight: 1000 } },
                React.createElement(Table, { stickyHeader: true },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null),
                            this.state.tableData.columns.map(c => (React.createElement(TableCell, null, c.name))))),
                    React.createElement(TableBody, null, this.state.tableData.rows.map(r => (React.createElement(TableRow, { key: r.id },
                        React.createElement(TableCell, { component: "th", scope: "row", style: { position: 'sticky', top: 0, left: 0, zIndex: 99999, backgroundColor: '#1E1E1E' } }, r.name),
                        this.state.tableData.columns.filter(c => c.id < r.id).map(c => (React.createElement(TableCell, null,
                            React.createElement(Checkbox, { icon: React.createElement(DoneIcon, null), checkedIcon: React.createElement(NotInterestedIcon, { htmlColor: "#BF0D3E" }), checked: r.relations.filter(sr => sr.id == c.id).length > 0, name: c.name, id: c.id, onChange: (event) => this.handleChange(c, r), title: c.name, value: "primary" }))))))))))));
    }
}
