import * as React from "react";
import { Input } from "@material-ui/core";
export class ImageUpload extends React.Component {
    constructor() {
        super(...arguments);
        this.imageUploadHandler = (event) => {
            if (event.target.files.length > 0)
                this.getBase64(event.target.files[0], (result) => {
                    this.props.onChange(result);
                });
        };
    }
    getBase64(file, resultFunc) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resultFunc(reader.result);
        };
        reader.onerror = (error) => {
            console.log(`Error: ${error}`);
        };
    }
    render() {
        return React.createElement(Input, { onChange: this.imageUploadHandler, type: "file" });
    }
}
