import * as React from "react";
import { Grid, MenuItem, DialogTitle, IconButton, DialogContent, CircularProgress } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ServiceSquare } from "./ServiceSelection";
import { BarberSquare } from "./BarberSquare";
import { Dialog } from "@material-ui/core";
import { UserSelector } from "./UserSelector";
import { GroupCreator } from "./GroupCreator";
import { ApiUtils } from "../../../Services/ApiUtils";
import Keyboard from "react-simple-keyboard";
import 'react-simple-keyboard/build/css/index.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { floor } from "lodash";
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';
export class CheckinSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: props.user.phoneNumber,
            delayValue: 0,
            reminderValue: 10,
            guestModal: false,
            userModal: false,
            displayError: false,
            disableConfirmButton: true
        };
    }
    componentDidMount() {
        // POST to API customer information, get back a confirmation number
        //var services = this.props.services.map(s => { return { employeeId: this.props.barber.id, serviceId: s.id } })
        var services = this.props.services.map(s => { return { employeeId: this.props.barber.referenceId, serviceId: s.referenceId }; }); // TEMP: For testing
        var data = {
            User: this.props.user,
            services: services
        };
        ApiUtils.post(`kiosk/CreateWalkinForShop/${this.props.shopId}`, data).then(r => {
            if (r.status == 200) {
                var waitTime = r.data.waitTime;
                switch (true) {
                    case waitTime >= 50:
                        this.setState({ reminderValue: 35 });
                        break;
                    case waitTime >= 40:
                        this.setState({ reminderValue: 25 });
                        break;
                    case waitTime >= 25:
                        this.setState({ reminderValue: 15 });
                        break;
                    case waitTime >= 15:
                        this.setState({ reminderValue: 5 });
                        break;
                    default:
                        this.setState({ reminderValue: 5 });
                        break;
                }
                if (r.data.href) {
                    this.setState({
                        visitId: r.data.href.split('/').splice(-1)[0],
                        waitTime: waitTime,
                        // Setting the initial default notification timing
                        reminderValue: waitTime < 10 ? (waitTime < 5 ? 0 : 5) : this.state.reminderValue,
                        disableConfirmButton: false,
                    });
                }
            }
            else {
                this.generateError();
            }
        }, e => {
            this.generateError();
        });
    }
    /**
     * Attempts to get store information when populating error message
     */
    generateError() {
        let baseMessage = "Sorry we are unable to process your check-in request. ";
        baseMessage += this.props.businessNumber !== ""
            ? "If you require assistance please contact the shop at"
            : "Contact the Shop for assistance";
        this.setState(Object.assign(Object.assign({}, this.state), { displayError: true, errorMessage: baseMessage }));
    }
    onWaitDelayChange(event) {
        this.setState({
            delayValue: event.target.value
        });
    }
    onReminderChanger(event) {
        this.setState({
            reminderValue: event.target.value
        });
    }
    onPhoneNumberChange(event) {
        var cleanedValue = this.formatPhoneNumber(event.target.value);
        this.setState({
            phoneNumber: cleanedValue
        });
    }
    validatePhoneNumber(number, language) {
        const validLanguages = ['en', 'us', 'ca', 'en-US', 'en-CA', 'en-NZ', 'en-AU'];
        if (!validLanguages.includes(language)) {
            return;
        }
        if (language == 'en' || language == 'ca' || language == 'us') {
            language = 'en-CA';
        }
        const countryCode = language.slice(-2);
        const parsedNumber = parsePhoneNumber(number, countryCode);
        if (!parsedNumber || !(parsedNumber === null || parsedNumber === void 0 ? void 0 : parsedNumber.isValid())) {
            return;
        }
        const fixedNumber = parsedNumber.format('NATIONAL');
        const formattedNumber = parsedNumber.format('E.164');
        if (fixedNumber !== number) {
            return formattedNumber;
        }
        return number;
    }
    formatPhoneNumber(value) {
        const phoneNumber = value.replace(/[^\d+]/g, '');
        const formatter = new AsYouType(navigator.language.toUpperCase().split(/-|_/)[1]);
        formatter.input(phoneNumber);
        const phoneNumberObj = formatter.getNumber();
        if (phoneNumberObj) {
            const countryCode = phoneNumberObj.countryCallingCode;
            const internationalNumber = phoneNumberObj.formatInternational();
            const country = phoneNumberObj.country;
            return `${internationalNumber}`;
        }
        return phoneNumber;
    }
    onComplete() {
        // POST confirmation number
        var data = {
            visitId: this.state.visitId,
            smsNumber: this.formatPhoneNumber(this.state.phoneNumber),
            notificationMinutes: this.state.reminderValue,
            delay: this.state.delayValue
        };
        ApiUtils.post(`kiosk/ConfirmWalkin/${this.props.shopId}`, data).then(r => {
            if (r.status == 200) {
                var availableUsers = this.props.availableUsers.filter(u => u != this.props.user && this.props.checkedInUsers.filter(cu => cu == u).length == 0);
                if (availableUsers.length == 0)
                    this.props.onComplete();
                else
                    this.setState({ userModal: true });
            }
            else {
                // TODO Handle ConfirmWalkIn API error
                console.log("not ok", r);
            }
        });
    }
    onAddGuest() {
        this.setState({
            guestModal: true
        });
    }
    onBack() {
        // Cancel appointment and go back
        var data = {
            visitId: this.state.visitId
        };
        ApiUtils.post(`kiosk/CancelWalkin/${this.props.shopId}`, data).then(r => {
            if (r.status == 200) {
                this.props.onBack();
            }
            // Else we should display an error message to the user.
        });
    }
    guestDialog() {
        var availableUsers = this.props.availableUsers.filter(u => u != this.props.user && this.props.checkedInUsers.filter(cu => cu == u).length == 0);
        return (React.createElement(Dialog, { open: this.state.guestModal, maxWidth: 'lg', fullWidth: true, style: { textAlign: 'center' } },
            React.createElement(DialogTitle, null,
                React.createElement(IconButton, { "aria-label": "close", onClick: () => this.setState({ guestModal: false }), style: { position: "absolute", right: 0, top: 0 } },
                    React.createElement(CloseIcon, null)),
                React.createElement(DialogContent, null,
                    React.createElement(GroupCreator, { users: availableUsers, onCancelClick: () => this.setState({ guestModal: false }), onConfirmClick: (group) => this.props.onConfirm(group[0]), lightTheme: true, isMobile: this.props.isMobile })))));
    }
    userDialog() {
        var availableUsers = this.props.availableUsers.filter(u => u != this.props.user && this.props.checkedInUsers.filter(cu => cu == u).length == 0);
        return (React.createElement(Dialog, { open: this.state.userModal, maxWidth: 'lg', fullWidth: true, style: { textAlign: 'center' } },
            React.createElement(DialogTitle, null,
                React.createElement(IconButton, { "aria-label": "close", onClick: () => this.setState({ userModal: false }), style: { position: "absolute", right: 0, top: 0 } },
                    React.createElement(CloseIcon, null)),
                React.createElement(DialogContent, null,
                    React.createElement(UserSelector, { isMobile: this.props.isMobile, users: availableUsers, onConfirm: (user) => this.props.onConfirm(user), onComplete: this.onComplete })))));
    }
    renderConfirmDialog() {
        if (this.state.guestModal)
            return this.guestDialog();
        if (this.state.userModal)
            return this.userDialog();
        return (React.createElement(React.Fragment, null));
    }
    renderServices() {
        var serviceListWidth = this.props.services.length > 3 ? 3 : this.props.services.length;
        return (React.createElement(Grid, { item: true, spacing: 5, xs: 6, style: { textAlign: "center" } },
            React.createElement("div", { className: "tg-barbers-category", style: { padding: 10, fontSize: 60, textAlign: 'left', borderBottom: '5px #BF0D3E solid', marginBottom: 30 } }, "Services Selected"),
            React.createElement("div", { style: { width: 230 * serviceListWidth }, className: this.props.services.length > 4 ? 'tg-small-services' : '' }, this.props.services.map(s => React.createElement(ServiceSquare, { shopId: this.props.shopId, service: s, onSelection: () => { return; }, disabled: false, widthOveride: "150px", selectOveride: true })))));
    }
    renderWaitTime() {
        if (this.state.waitTime) {
            let totalMinutes = this.state.waitTime;
            if (totalMinutes < 60) {
                return `${totalMinutes}m`;
            }
            let hours = floor(totalMinutes / 60);
            let minutes = totalMinutes % 60;
            return `${hours}h ${minutes}m`;
        }
        else {
            return "Calculating...";
        }
    }
    renderBarber() {
        return (React.createElement(Grid, { item: true, spacing: 5, xs: 6, style: { textAlign: "center" } },
            React.createElement("div", { className: "tg-barbers-category", style: { padding: 10, fontSize: 60, textAlign: 'left', borderBottom: '5px #BF0D3E solid', marginBottom: 30 } },
                "Selected Barber",
                React.createElement("span", { style: { float: "right", fontSize: 60, color: '#BF0D3E' } }, this.renderWaitTime()),
                React.createElement("span", { style: { float: "right", fontSize: 18, maxWidth: 80, marginTop: 18 } }, "Estimated Wait Time:")),
            React.createElement("div", { style: { maxHeight: 246 } },
                React.createElement(BarberSquare, { isMobile: this.props.isMobile, barber: this.props.barber, hideWaitTime: true, onSelection: (barber) => { return; }, overideSize: 270 }))));
    }
    renderError() {
        return (React.createElement(Dialog, { open: this.state.displayError },
            React.createElement(DialogTitle, null, "Error"),
            React.createElement(DialogContent, null,
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    this.state.errorMessage,
                    this.props.businessNumber !== ""
                        ?
                            React.createElement("div", null,
                                React.createElement("a", { style: { color: "#BF0D3E", fontSize: "24px" }, href: "tel:PHONE_NUM" }, this.props.businessNumber))
                        :
                            React.createElement("div", null),
                    React.createElement("div", { style: { position: "relative", width: "100%", height: "50px" } },
                        React.createElement("div", { className: "tg-next-button", style: { right: "0px", bottom: "0px", textAlign: "center", height: "40px", marginRight: "10px", paddingTop: "10px", width: "100px" }, onClick: () => this.onBack() }, "Back"))))));
    }
    calculateReminderOptions() {
        var waitTime = this.state.waitTime || 0;
        var availableOptions = [];
        if (waitTime > 10)
            availableOptions.push(5);
        if (waitTime > 15)
            availableOptions.push(10);
        if (waitTime > 20)
            availableOptions.push(15);
        if (waitTime > 30)
            availableOptions.push(25);
        if (waitTime > 60)
            availableOptions.push(45);
        if (waitTime > 75)
            availableOptions.push(60);
        return availableOptions;
    }
    renderNotification() {
        return (React.createElement(Grid, { item: true, spacing: 5, xs: 6, style: { textAlign: "left", marginTop: -20 } },
            React.createElement("div", { className: "tg-barbers-category", style: { padding: 10, fontSize: 60, textAlign: 'left', borderBottom: '5px #BF0D3E solid', marginBottom: 30 } }, "Notification"),
            React.createElement("div", null, this.state.waitTime != 0 && this.state.waitTime != undefined ? "We'll text you a reminder closer to your estimated check-in." : "Not Available")));
    }
    renderReminderTimes() {
        var reminderTimes = this.calculateReminderOptions();
        return reminderTimes.map(t => (React.createElement(MenuItem, { value: t }, t)));
    }
    renderButtons() {
        return (React.createElement(Grid, { item: true, spacing: 5, xs: 6, style: { textAlign: "right", paddingRight: 50, bottom: 0 } },
            React.createElement(Grid, { spacing: 5, container: true, style: { height: "100%" }, alignContent: "flex-end", justify: "flex-end" },
                React.createElement(Grid, { item: true, xs: 6, style: { display: "flex", flexDirection: "row", minWidth: "400px" } },
                    React.createElement("div", { className: "tg-back-button", onClick: (event) => this.onBack() },
                        React.createElement("div", { className: "tg-button-arrow-container" },
                            React.createElement(ArrowBackIosIcon, { className: "tg-back-button-arrow" })),
                        React.createElement("div", { className: "tg-back-button-text" }, "Back")),
                    this.state.disableConfirmButton
                        ?
                            React.createElement(Grid, { container: true, style: { height: "100%", color: '#E23231', backgroundColor: 'transparent' }, alignItems: "center" },
                                React.createElement(CircularProgress, { style: { margin: "auto" } }))
                        :
                            React.createElement("div", { className: this.state.disableConfirmButton ? "tg-next-button btn-disable" : "tg-next-button", onClick: (event) => this.onComplete() },
                                React.createElement("div", { style: { width: "100%", letterSpacing: 5, fontSize: 45, fontFamily: "Big Noodle Titling" } }, "Complete"),
                                React.createElement("div", { style: { width: "100%", textAlign: "center", textTransform: "uppercase", fontFamily: 'Gotham Book', paddingBottom: "10px" } }, "Check-In"))))));
    }
    onKeyboardPress(input) {
        this.setState({
            phoneNumber: input
        });
    }
    renderKeyboard() {
        // Keyboard is disabled for now.
        if (this.state.displayKeyboard) {
            return (React.createElement("div", null,
                React.createElement(Keyboard, { keyboardRef: r => (this.keyboard = r), onChange: (input) => this.onKeyboardPress(input), baseClass: "tg-summary-keyboard", layout: { default: ["1 2 3", "4 5 6", "7 8 9", '{bksp} 0'] } })));
        }
    }
    render() {
        return (React.createElement("div", { style: { overflow: "hidden" } },
            this.renderConfirmDialog(),
            this.renderError(),
            React.createElement(Grid, { spacing: 5, container: true, alignContent: "center", justify: "center", style: { padding: "0 40px" } },
                this.renderServices(),
                this.renderBarber(),
                this.renderNotification(),
                this.renderButtons(),
                React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { color: "black", position: "absolute", bottom: 0, width: "25%" } }, this.renderKeyboard()))));
    }
}
