import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
export default class IdleUserDialog extends React.Component {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Dialog, { open: this.props.open, onClose: (e) => this.props.onClose(e) },
                React.createElement(DialogTitle, null, this.props.titleText || "Are you still there?"),
                React.createElement(DialogContent, null, this.props.dialogText || "Please press anywhere if you require more time making your decision."),
                React.createElement(DialogActions, null))));
    }
}
