import * as React from "react";
import { EntityTable } from "../../../Common/Tables/EntityTable";
import { OverviewPage } from "../../../Common/Pages/OverviewPage";
import { DeviceTable } from "./Devices";
import { ShortcutsShopWindow } from "../../../Common/Windows/ShortcutsShopWindow";
import { TableType } from "../../../../Services/TableStateManager";
export class ShopTable extends React.Component {
    constructor() {
        super(...arguments);
        this.data = {
            tabs: [
                {
                    label: 'Devices',
                    value: 0,
                },
            ],
            breadCrumbs: ['Shops Table'],
            pages: [
                (data) => (DeviceTable(data)),
            ]
        };
        this.shopPage = new OverviewPage(this.data);
        this.tableProps = {
            type: TableType.Shops,
            apiController: "shop",
            tableTitle: "Shops",
            overviewComponent: this.shopPage,
            editableRegion: true,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Description', field: 'description' },
                { title: 'Shortcuts Id', field: 'shortcutsId', type: 'numeric' },
                { title: 'Region', field: 'region.name', editable: 'never' },
                { title: 'Is Private', field: 'isPrivate', type: 'boolean' }
            ],
            actions: [
                {
                    icon: 'cloud_download',
                    tooltip: 'Add from Shortcuts',
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                        this.setState({
                            modalOpen: true,
                            tableKey: 0,
                        });
                    }
                }
            ]
        };
        this.handleClose = () => {
            this.setState({ modalOpen: false });
        };
        this.handleSave = () => {
            this.setState({
                modalOpen: false,
                tableKey: 1
            });
        };
    }
    componentWillMount() {
        this.shopPage.setState({
            activeTab: 0
        });
        this.setState({
            modalOpen: false,
            tableKey: 0
        });
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(ShortcutsShopWindow, { open: this.state.modalOpen, onClose: this.handleClose, onSave: this.handleSave }),
            React.createElement(EntityTable, Object.assign({ key: this.state.tableKey }, this.tableProps))));
    }
}
