import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Select, MenuItem } from "@material-ui/core";
import { ApiUtils } from "../../../Services/ApiUtils";
export class ChangeRegionWindow extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChange = (event) => {
            this.setState({
                selectedId: event.target.value,
            });
        };
        this.handleSave = (event) => {
            let region = this.state.regions.filter(s => s.id == this.state.selectedId);
            if (region.length > 0) {
                let record = this.props.record;
                if (record.regionId)
                    record.regionId = region[0].id;
                else if (record.parentRegionId)
                    record.parentRegionId = region[0].id;
                this.props.onSave(record, this.props.record).then(r => {
                    this.setState({
                        selectedId: 0
                    });
                }).catch(e => {
                    this.setState({
                        error: true,
                        errorText: e.response.data
                    });
                });
            }
        };
    }
    componentWillMount() {
        ApiUtils.get('region/read').then(r => {
            this.setState({
                regions: r.data.data,
                error: false,
            });
        });
    }
    render() {
        if (!this.state || !this.state.regions) {
            return (React.createElement("div", null));
        }
        return (React.createElement(Dialog, { open: this.props.open, onClose: this.props.onClose },
            React.createElement(DialogTitle, null, "Change Region"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null,
                    "Change the Region of ",
                    this.props.record.name),
                React.createElement(Select, { onChange: this.handleChange, value: this.state.selectedId, fullWidth: true }, this.state.regions.map(s => React.createElement(MenuItem, { key: s.id, value: s.id }, s.name)))),
            React.createElement(DialogContentText, { color: "primary" }, this.state.error ? `Error: ${this.state.errorText}` : ''),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: this.props.onClose, color: "secondary" }, "Cancel"),
                React.createElement(Button, { onClick: this.handleSave, color: "primary" }, "Save"))));
    }
}
