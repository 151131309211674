import * as React from "react";
import { DialogTitle, Dialog, DialogContent, DialogContentText, Select, MenuItem, DialogActions, Button, FormLabel } from "@material-ui/core";
import { ApiUtils } from "../../../Services/ApiUtils";
import * as _ from "lodash";
export class ShortcutsServiceWindow extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChange = (event) => {
            this.setState({
                serviceValues: event.target.value,
            });
        };
        this.handleSave = (event) => {
            let services = this.state.services.filter(s => _.includes(this.state.serviceValues, s.shortcutsId));
            if (services.length > 0) {
                services.forEach(service => {
                    if (service.shortcutsId == 0)
                        return;
                    let data = {
                        name: service.name,
                        shortcutsId: service.shortcutsId,
                        onPremiseId: service.onPremiseId,
                        serviceCategoryId: this.props.serviceCategoryId
                    };
                    ApiUtils.post('service/create', data).then(r => this.props.onSave());
                });
                this.setState({
                    serviceValues: [0]
                });
            }
        };
    }
    componentWillMount() {
        ApiUtils.get(`service/FromShortcuts/`).then(r => {
            this.setState({
                services: r.data,
                serviceValues: [0]
            });
        });
    }
    render() {
        if (!this.state || !this.state.services) {
            return (React.createElement("div", null));
        }
        return (React.createElement("div", null,
            React.createElement(Dialog, { open: this.props.open, onClose: this.props.onClose },
                React.createElement(DialogTitle, null, "Add from Shortcuts"),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, null),
                    React.createElement(FormLabel, null, "Service"),
                    React.createElement(Select, { onChange: this.handleChange, value: this.state.serviceValues, fullWidth: true, multiple: true },
                        React.createElement(MenuItem, { key: 0, value: 0 }, "None"),
                        this.state.services.map(s => React.createElement(MenuItem, { key: s.shortcutsId, value: s.shortcutsId }, s.name)))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: this.props.onClose, color: "secondary" }, "Cancel"),
                    React.createElement(Button, { onClick: this.handleSave, color: "primary" }, "Save")))));
    }
}
