import * as React from "react";
import { ApiUtils } from "../../Services/ApiUtils";
import { Typography } from "@material-ui/core";
import { DisplayType } from "../Display/Display";
import { Wallboard } from "../Display/Wallboard";
import { DigitalSign } from "../Display/DigitalSign";
import { LoadingSpinner } from "../Common/Pages/LoadingSpinner";
import { DeviceCommandType } from "../../Commands/DeviceCommandType";
import { CheckinKiosk } from "../Display/CheckinKiosk";
export class Device extends React.Component {
    constructor(props) {
        super(props);
    }
    getDeviceDetails() {
        return ApiUtils.get(`device/view/${this.props.match.params.id}`)
            .then(r => {
            this.setState({ details: r.data });
        });
    }
    getDeviceCommands() {
        return ApiUtils.get(`device/commandqueue/${this.props.match.params.id}`)
            .then(response => {
            if (response.data) {
                response.data.map(deviceCommand => {
                    if (this.state.commandQueue.filter(command => command.guid == deviceCommand.guid).length == 0) {
                        this.state.commandQueue.push(deviceCommand);
                        this.invokeCommand(deviceCommand);
                    }
                });
            }
        });
    }
    static refreshAllDeviceBrowsers() {
        return ApiUtils.get(`device/refreshAllDeviceBrowsers/`);
    }
    static refreshDeviceBrowser(id) {
        return ApiUtils.get(`device/refreshDeviceBrowser/${id}`);
    }
    invokeCommand(deviceCommand) {
        switch (deviceCommand.type) {
            case DeviceCommandType.RefreshDeviceConfiguration:
                this.getDeviceDetails();
                break;
            case DeviceCommandType.RefreshDeviceBrowsers:
                let commandDate = new Date(Date.parse(deviceCommand.createdDate.toString()));
                let lastRefreshDate = new Date(Date.parse(this.state.lastRefreshDate.toString()));
                if (commandDate > lastRefreshDate) {
                    window.location.reload();
                }
                break;
        }
    }
    componentDidCatch(error, info) {
        console.log('Error displaying Device');
        console.log(error);
        console.log(info);
        this.setState({
            error: { error: error, info: info }
        });
    }
    componentDidMount() {
        let initializeLastRefresh = () => {
            for (let command of this.state.commandQueue) {
                if (command.type === DeviceCommandType.RefreshDeviceBrowsers) {
                    this.setState({
                        lastRefreshDate: command.createdDate
                    });
                }
            }
            if (this.state.lastRefreshDate === undefined) {
                this.setState({ lastRefreshDate: new Date() });
            }
        };
        this.setState({ commandQueue: [] }, initializeLastRefresh);
        this.getDeviceDetails();
        this.getDeviceCommands();
        setInterval(() => {
            this.getDeviceCommands();
        }, 30000);
    }
    render() {
        if (this.state == null || this.state.details == null) {
            return React.createElement(LoadingSpinner, null);
        }
        if (this.state.error != null) {
        }
        // If the Display does not have configuration, display text saying so.
        if (!this.state.details.displayConfiguration) {
            return (React.createElement(Typography, { variant: "h1" }, "Display has no configuration"));
        }
        //HACK: Typescript for some reason thinks this property is a string when accessed here. We have to parse it from a string to the type again.
        var config = JSON.parse(this.state.details.displayConfiguration.toString());
        if (this.state.details.displayType == DisplayType.Wallboard)
            return React.createElement(Wallboard, { config: config, shopId: this.state.details.shopId });
        else if (this.state.details.displayType == DisplayType.DigitalSign)
            return React.createElement(DigitalSign, { config: config, shopId: this.state.details.shopId });
        else if (this.state.details.displayType == DisplayType.Kiosk)
            return React.createElement(CheckinKiosk, { config: config, deviceId: this.props.match.params.id, shopId: this.state.details.shopId, region: this.props.match.params.region });
        else
            return (React.createElement("div", null,
                "No Display available for type: ",
                this.state.details.displayType,
                "."));
    }
}
