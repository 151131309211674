import * as React from 'react';
import { createMuiTheme } from '@material-ui/core';
export var DisplayType;
(function (DisplayType) {
    DisplayType[DisplayType["DigitalSign"] = 0] = "DigitalSign";
    DisplayType[DisplayType["Wallboard"] = 1] = "Wallboard";
    DisplayType[DisplayType["Kiosk"] = 2] = "Kiosk";
})(DisplayType || (DisplayType = {}));
export class Display extends React.Component {
    constructor() {
        super(...arguments);
        this.promotionChangeInterval = 25000; //Promo change internval in milliseconds
        this.theme = createMuiTheme();
        this.intervalId = null;
    }
    updateConfiguration() {
        if (this.props.config) {
            this.promotionChangeInterval = this.props.config.promotionInterval * 1000;
            this.theme = createMuiTheme({
                palette: {
                    type: 'dark',
                    background: {
                        default: this.props.config.backgroundColor
                    },
                    text: {
                        primary: this.props.config.fontColor,
                    }
                },
                overrides: {
                    MuiGrid: {
                        container: {
                            backgroundColor: this.props.config.backgroundColor,
                            backgroundImage: this.props.config.backgroundImage ? `url('/api/v1/asset/view/${this.props.config.backgroundImage}')` : '',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            color: this.props.config.fontColor
                        },
                        item: {
                            backgroundColor: this.props.config.backgroundColor,
                            color: this.props.config.fontColor
                        }
                    },
                    MuiInput: {
                        root: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10
                        },
                        input: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10
                        }
                    },
                    MuiSelect: {
                        root: {
                            backgroundColor: "#fefefe",
                            borderRadius: 10
                        },
                        select: {
                            backgroundColor: "#fefefe"
                        }
                    }
                }
            });
        }
    }
}
