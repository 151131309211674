import * as React from "react";
import classNames from "classnames";
import { withStyles, createStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { SideNav } from "./SideNav";
import { Route } from "react-router-dom";
import { Settings } from "./Pages/Settings/Settings";
import { DisplayTable, PromotionTable, UserTable, ShopTable, ServiceCategoryTable, AssetTable } from "./Pages/Tables/";
import { AuthRole } from "../../Services/AuthRole";
import { RegionTable } from "./Pages/Tables/Regions";
import { ApiUtils } from "../../Services/ApiUtils";
import { Icon } from "@material-ui/core";
import { withAdalLoginApi } from "../../Services/AzureAdAuth";
import { LoadingSpinner } from "../Common/Pages/LoadingSpinner";
import AuthError from "./Pages/AuthError";
import { MobileSettings } from "./Pages/Mobile/MobileSettings";
const drawerWidth = 240;
const styles = (theme) => createStyles({
    root: {
        display: 'flex',
        backgroundColor: '#2D2D30'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '0 8px', color: '#fff' }, theme.mixins.toolbar),
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#171913',
        borderBottom: '3px solid #BF0D3E',
        color: '#fff'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        color: '#fff',
    },
    menuButtonClose: {
        color: '#fff'
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundImage: "linear-gradient(rgba(12,3,5,0.8),rgba(12,3,5,0.8)),url('/Content/Images/drawerImage.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'left'
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing.unit * 2,
    },
});
const navItems = {
    items: [
        { text: "Promotions", icon: "crop_landscape", to: "/management/promotions", visibleToRoles: [AuthRole.Any] },
        { text: "Displays", icon: "web", to: "/management/displays", visibleToRoles: [AuthRole.Any] },
        { text: "Users", icon: "person", to: "/management/users", visibleToRoles: [AuthRole.HeadOfficeAdmin] },
        { text: "Shops", icon: "store", to: "/management/shops", visibleToRoles: [AuthRole.HeadOfficeAdmin] },
        { text: "Services", icon: "loyalty", to: "/management/services", visibleToRoles: [AuthRole.HeadOfficeAdmin] },
        { text: "Assets", icon: "image", to: "/management/assets", visibleToRoles: [AuthRole.HeadOfficeAdmin] },
        { text: "Mobile", icon: "smartphone", to: "/management/mobile", visibleToRoles: [AuthRole.HeadOfficeAdmin] },
        { text: "Settings", icon: "settings", to: "/management/settings", visibleToRoles: [AuthRole.Any] }
    ]
};
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
        this.handleDrawerOpen = () => {
            this.setState({ open: true });
        };
        this.handleDrawerClose = () => {
            this.setState({ open: false });
        };
        this.changeRegion = (event, data) => {
            ApiUtils.post(`Region/SetRegion/${data.id}`)
                .then(r => {
                this.setState({ region: data.name });
                var currentPath = this.props.location.pathname;
                this.props.history.push('/', this.state);
                this.props.history.push(currentPath, this.state);
            });
        };
        this.state = {
            open: true,
            region: 'None',
            regionOpen: false,
        };
    }
    componentWillMount() {
        ApiUtils.configure(null);
        if (!this.state || !this.state.region || this.state.region == 'None') {
            ApiUtils.get('Region/CurrentRegion').then(r => {
                if (r && r.data) {
                    this.setState({
                        region: r.data.name
                    });
                }
            });
        }
    }
    render() {
        const classes = this.props.classes;
        const { match } = this.props;
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement("div", { className: classes.root },
                React.createElement(CssBaseline, null),
                React.createElement(AppBar, { position: "absolute", className: classNames(classes.appBar, this.state.open && classes.appBarShift) },
                    React.createElement(Toolbar, { disableGutters: !this.state.open, className: classes.toolbar },
                        React.createElement(IconButton, { color: "inherit", "aria-label": "Open drawer", onClick: this.handleDrawerOpen, className: classNames(classes.menuButton, this.state.open && classes.menuButtonHidden) },
                            React.createElement(MenuIcon, null)),
                        React.createElement(Typography, { variant: "h6", color: "inherit", noWrap: true, className: classes.title }, "GFT Management"),
                        React.createElement(Typography, { align: "right", color: "inherit" },
                            "Current Region: ",
                            this.state.region),
                        React.createElement(IconButton, { color: "inherit", onClick: () => this.setState({ regionOpen: true }) },
                            React.createElement(Icon, null, "place"))),
                    React.createElement(Drawer, { anchor: "top", open: this.state.regionOpen, onClose: () => this.setState({ regionOpen: false }) },
                        React.createElement(RegionTable, { onRegionChange: this.changeRegion }))),
                React.createElement(Drawer, { variant: "permanent", classes: {
                        paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }, open: this.state.open },
                    React.createElement("div", { className: classes.toolbarIcon },
                        React.createElement(IconButton, { className: classes.menuButtonClose, onClick: this.handleDrawerClose },
                            React.createElement(ChevronLeftIcon, null))),
                    React.createElement(Divider, null),
                    React.createElement(Divider, null),
                    React.createElement(SideNav, Object.assign({}, navItems))),
                React.createElement("main", { className: classes.content },
                    React.createElement("div", { className: classes.appBarSpacer }),
                    React.createElement(Route, { path: `/management/promotions`, component: PromotionTable }),
                    React.createElement(Route, { path: `/management/displays`, component: DisplayTable }),
                    React.createElement(Route, { path: `/management/users`, component: UserTable }),
                    React.createElement(Route, { path: `/management/shops`, component: ShopTable }),
                    React.createElement(Route, { path: `/management/services`, component: ServiceCategoryTable }),
                    React.createElement(Route, { path: `/management/assets`, component: AssetTable }),
                    React.createElement(Route, { path: `/management/mobile`, component: MobileSettings }),
                    React.createElement(Route, { path: `/management/settings`, component: Settings })))));
    }
}
export default withAdalLoginApi(withStyles(styles)(Dashboard), () => React.createElement(LoadingSpinner, null), (error) => { console.log(error); return React.createElement(AuthError, { ErrorMessage: error.message }); });
