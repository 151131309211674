import * as React from 'react';
import './Content/CSS/App.css';
import Dashboard from "./Components/ManagementDashboard/Dashboard";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import { Device } from "./Components/Device/Device";
//Entry point of application. It must be wrapped in a BrowserRouter which provides routing and history functionality
export function App() {
    document.title = "GFT Management";
    return (React.createElement(BrowserRouter, null,
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/management", component: Dashboard }),
            React.createElement(Route, { path: "/device/:id/:region?", component: Device }),
            React.createElement(Route, { render: () => React.createElement(Redirect, { to: "/management" }) }))));
}
export default App;
