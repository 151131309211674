import * as React from 'react';
import { Display } from "./Display";
import { MuiThemeProvider, CssBaseline, Grid } from '@material-ui/core';
import { PromotionQueue } from './Promotion/PromotionQueue';
import { VisitorQueue } from "./FullPage/VisitorQueue";
import { ApiUtils } from "../../Services/ApiUtils";
import { LoadingSpinner } from '../Common/Pages/LoadingSpinner';
// Pulls in visitor data, pages it, and displays each page on an interval
export class Wallboard extends Display {
    constructor() {
        super(...arguments);
        this.visitorsPerPage = 6; //Default value is 6
        this.pageChangeInterval = 10000; //Page change interval in milliseconds
        this.promotions = [];
    }
    updateConfiguration() {
        super.updateConfiguration();
        if (this.props.config) {
            this.visitorsPerPage = this.props.config.rowsPerPage * this.props.config.columnsPerPage;
            this.pageChangeInterval = this.props.config.pageInterval * 1000;
        }
    }
    update() {
        if (!this.state) {
            this.loadData()
                .then(res => {
                this.setState({
                    currentPage: 0,
                    pagedVisitors: this.getPagedWallboards(res),
                    error: false,
                    promotions: this.promotions || []
                });
            });
            return;
        }
        let nextPage = this.state.currentPage + 1 || 0;
        let numPages = this.state.pagedVisitors.length || 0;
        if (nextPage >= numPages) {
            this.loadData()
                .then(res => {
                this.setState({
                    currentPage: 0,
                    pagedVisitors: this.getPagedWallboards(res),
                    error: false,
                    promotions: this.promotions || []
                });
            })
                .catch(err => {
                this.setState({
                    error: true
                });
            });
        }
        else {
            this.setState({ currentPage: nextPage });
        }
    }
    componentDidCatch(error, info) {
        console.log('Error displaying Wallboard');
        console.log(error);
        console.log(info);
    }
    getPagedWallboards(fullPageModel) {
        let pagedWallboards = [];
        let visitorsPerPage = (this.props.config) ? this.props.config.rowsPerPage * this.props.config.columnsPerPage : 6;
        this.promotions = fullPageModel.promotions || [];
        const numVisitors = fullPageModel.visitors.length;
        let visitors = fullPageModel.visitors.map(v => this.CalculateWaitTimes(v, this.props.config.waitTimeOffset));
        visitors = this.AdjustVisitorWaitTime(visitors);
        let pageCount = 0;
        for (let i = 0; i < numVisitors; i += visitorsPerPage) {
            pagedWallboards.push({
                pageModel: {
                    titleText: this.props.config.titleText,
                    visitors: visitors.slice(i, i + visitorsPerPage)
                },
                configuration: this.props.config,
                currentPage: pageCount++
            });
        }
        if (!pagedWallboards || pagedWallboards.length === 0) {
            pagedWallboards.push({
                pageModel: {
                    titleText: this.props.config.titleText,
                    visitors: [],
                },
                configuration: this.props.config,
                currentPage: 0
            });
        }
        return pagedWallboards;
    }
    render() {
        if (!this.state) {
            return (React.createElement(LoadingSpinner, null));
        }
        else if (this.state.error) {
            //TODO: The error message should render in it's own component.
            return (React.createElement(MuiThemeProvider, { theme: this.theme },
                React.createElement(CssBaseline, null),
                React.createElement(Grid, { container: true, style: { height: "100%" }, alignItems: "center" },
                    React.createElement("h1", { className: "wallboard-error", style: { color: this.props.config.fontColor || "white", textAlign: "center", margin: 0, width: "100%" } }, this.props.config.connectionErrorMessage || "Connection issue. No data was loaded."))));
        }
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(CssBaseline, null),
            React.createElement(VisitorQueue, Object.assign({}, this.state.pagedVisitors[this.state.currentPage])),
            React.createElement(PromotionQueue, { refreshData: () => this.refreshPromotions(), promotions: this.promotions, changeInterval: this.promotionChangeInterval })));
    }
    refreshPromotions() {
        this.loadData().then(res => {
            this.setState({
                promotions: res.promotions || []
            });
        });
    }
    componentWillMount() {
        this.updateConfiguration();
        if (this.pageChangeInterval) {
            this.intervalId = setInterval(() => {
                try {
                    this.update();
                }
                catch (e) {
                    console.log(e);
                }
            }, this.pageChangeInterval);
        }
        else
            this.update();
    }
    componentWillUnmount() {
        if (this.intervalId)
            clearInterval(this.intervalId);
    }
    loadData() {
        return ApiUtils.get(`Display/Wallboard/${this.props.shopId}`).then(r => { return r.data; });
    }
    CalculateWaitTimes(visitor, offset) {
        // TODO: Do to a bug with TS this parameter is being interpreted as an object and not a number.
        let offsetValue = parseInt(offset.toString());
        if (offsetValue == 0) {
            visitor.soonestWaitTime = visitor.estimatedWaitTime;
            visitor.longestWaitTime = visitor.estimatedWaitTime;
            return visitor;
        }
        var waitTimeSeconds = (visitor.estimatedWaitTime) * 60;
        var quePositionOffset = ((visitor.id * 60) + offsetValue);
        var soonestWaitTime = Math.max(0, Math.round((waitTimeSeconds - (quePositionOffset)) / 60));
        var longestWaitTime = Math.round((waitTimeSeconds + quePositionOffset) / 60);
        visitor.soonestWaitTime = soonestWaitTime;
        visitor.longestWaitTime = longestWaitTime;
        return visitor;
    }
    // If a visitors soonest wait time is less than another visitor before them in the queue
    // set their soonest time to theirs.
    AdjustVisitorWaitTime(visitors) {
        if (visitors.length < 2)
            return visitors;
        let currentIndex = visitors.length - 1;
        while (currentIndex > 0) {
            for (let i = currentIndex - 1; i > 0; i--) {
                if (visitors[currentIndex].soonestWaitTime < visitors[i].soonestWaitTime) {
                    visitors[currentIndex].soonestWaitTime = visitors[i].soonestWaitTime;
                    continue;
                }
            }
            currentIndex--;
        }
        return visitors;
    }
}
