import * as React from 'react';
import { ApiUtils } from '../../../Services/ApiUtils';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import Input from '@material-ui/core/Input';
export class ForeignKeyDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: [],
            choice: props.value || ''
        };
    }
    getChoices() {
        var apiAction = this.props.apiAction || 'Read';
        // TODO: This needs to filter by Region.
        return ApiUtils.get(`${this.props.apiController}/${apiAction}/`)
            .then(r => {
            var choiceData = (this.props.nullable == true) ? [{ text: 'None', value: '' }] : [];
            r.data.data.map((d) => choiceData.push({ text: d.name, value: d.id }));
            this.setState({
                choices: choiceData,
            });
        });
    }
    componentDidMount() {
        this.getChoices();
    }
    handleChange(event, value) {
        this.props.onChange(this.props.returnEvent ? event : event.target.value);
        this.setState({
            choice: event.target.value
        });
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Input, { id: 'ffkeydd', name: this.props.fieldName, type: 'hidden', value: this.state.choice }),
            React.createElement(Select, { value: this.state.choice, onChange: (event, value) => this.handleChange(event, value), name: this.props.fieldName }, this.state.choices.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.text))))));
    }
}
