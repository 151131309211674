import * as React from "react";
import { DisplayType } from "../../../../Display/Display";
import { WallboardConfigurationForm } from "./WallboardConfigurationForm";
import { DigitalSignConfigurationForm } from "./DigitalSignConfigurationForm";
import { CheckinKioskConfigurationForm } from "./CheckinKioskConfiguration";
export class DisplayConfigurationForm extends React.Component {
    render() {
        if (this.props.displayType == DisplayType.Wallboard) {
            return (React.createElement(WallboardConfigurationForm, Object.assign({}, this.props)));
        }
        else if (this.props.displayType == DisplayType.DigitalSign) {
            return (React.createElement(DigitalSignConfigurationForm, Object.assign({}, this.props)));
        }
        else if (this.props.displayType == DisplayType.Kiosk) {
            return (React.createElement(CheckinKioskConfigurationForm, Object.assign({}, this.props)));
        }
    }
}
