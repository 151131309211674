import * as React from "react";
import { ApiUtils } from '../../../Services/ApiUtils';
export class ServiceSquareSimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.service.selected || false,
            modalOpen: false
        };
        if (!this.props.service.description) {
            var data = {
                shopId: this.props.shopId,
                serviceId: this.props.service.id
            };
            ApiUtils.post(`kiosk/GetServiceDescription`, data).then(r => {
                if (r.status == 200 && r.data) {
                    this.setState({ descriptionOverride: r.data.toString() });
                }
            });
        }
    }
    toggleModal(event) {
        event.stopPropagation();
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }
    renderImage(imageStyle) {
        if (this.props.service.assetId && this.props.service.assetId != "0") {
            return (React.createElement("div", { className: "tg-service-square__image" },
                React.createElement("img", { width: 100, height: 100, style: imageStyle, src: `/api/v1/asset/view/${this.props.service.assetId}`, alt: this.props.service.name })));
        }
    }
    render() {
        var selected = this.state.selected && this.props.selectOveride != true;
        var containerStyle = {
            cursor: this.props.disabled ? "not-allowed" : "pointer",
            filter: this.props.disabled ? "opacity(0.5) blur(1.5px)" : "",
            paddingBottom: 20,
            width: this.props.widthOveride || "45%",
            maxWidth: this.props.widthOveride || "144px",
            float: "left",
            marginRight: 5
        };
        var serviceSquareStyle = {
            backgroundColor: selected ? '#2C2C2C' : '#fefefe',
            color: selected ? '#fefefe' : '#222',
            padding: 5,
            borderRadius: 10,
            border: selected ? "3px solid #BF0D3E" : ""
        };
        var imageStyle = {
            filter: selected ? `invert(1) grayscale(1)` : 'invert(0)'
        };
        var buttonStyle = {
            color: "#BF0D3E",
            textDecoration: "underline solid 1px #BF0D3E",
            textTransform: 'lowercase',
            backgroundColor: 'transparent'
        };
        return (React.createElement("div", { className: `tg-service-square-container`, style: containerStyle },
            React.createElement("div", { className: "tg-service-square", style: serviceSquareStyle }, this.renderImage(imageStyle))));
    }
}
