import * as React from "react";
import { EntityTable } from "../../../Common/Tables/EntityTable";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { ImageUpload } from "../../../Common/Fields/ImageUpload";
import { TableType } from "../../../../Services/TableStateManager";
export class AssetTable extends React.Component {
    constructor() {
        super(...arguments);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
        this.tableProps = {
            type: TableType.Assets,
            apiController: "asset",
            tableTitle: "Assets",
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Description', field: 'description' },
                {
                    title: "Image", field: 'imageData', cellStyle: { maxWidth: 250, textOverflow: "ellipsis", overflow: "hidden" }, editComponent: props => React.createElement(ImageUpload, { onChange: props.onChange }),
                },
                {
                    title: 'Asset Type',
                    field: 'type',
                    lookup: {
                        0: 'Image',
                        1: "Background Image",
                        2: "Service Image",
                    }
                }
            ],
            actions: [],
            detailPanelActions: [
                {
                    icon: 'visibility',
                    openIcon: 'visibility_off',
                    tooltip: 'Preview',
                    render: rowData => {
                        return (React.createElement("img", { className: "visitor-queue-icon", src: `/api/v1/asset/view/${rowData.id}`, alt: `${rowData.Name}` }));
                    }
                }
            ]
        };
    }
    render() {
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(EntityTable, Object.assign({}, this.tableProps))));
    }
}
