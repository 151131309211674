import * as React from 'react';
import { KioskUserProfile } from './KioskUser';
import { Button, Grid, Dialog, DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { AddGuestForm } from './AddGuestForm';
import { ApiUtils } from '../../../Services/ApiUtils';
export class GroupCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsers: [],
            availableUsers: this.props.users,
            addGuestOpen: false
        };
    }
    selectUser(user) {
        var users = [];
        if (this.state.selectedUsers && this.state.selectedUsers.filter(u => u == user).length > 0) {
            users = this.state.selectedUsers.filter(u => u != user);
        }
        else {
            users = this.state.selectedUsers ? this.state.selectedUsers.concat(user) : [user];
        }
        this.setState({
            selectedUsers: users
        });
    }
    // Creates a new local guest based off the current account
    onAddGuest(firstName, lastName) {
        var user = Object.assign({}, this.state.selectedUsers[0]);
        user.firstName = firstName;
        user.lastName = lastName;
        this.props.users.push(user);
        this.setState({
            addGuestOpen: false,
            selectedUsers: this.state.selectedUsers.concat(user),
            availableUsers: this.state.availableUsers.concat(user)
        });
    }
    onAddTGUser(user) {
        if (this.props.users.length > 0) {
            var data = {
                SourceVisitorNumber: this.props.users[0].phoneNumber,
                TargetVisitorNumber: user.phoneNumber
            };
            ApiUtils.post(`kiosk/LinkVisitors`, data);
        }
        this.props.users.push(user);
        var selectedUsers = this.state.selectedUsers.concat(user);
        this.setState({
            addGuestOpen: false,
            selectedUsers: selectedUsers,
            availableUsers: this.state.availableUsers.concat(user)
        });
    }
    onConfirm() {
        if (this.state.selectedUsers && this.state.selectedUsers.length > 0) {
            this.props.onConfirmClick(this.state.selectedUsers);
        }
    }
    userSelector() {
        var textColor = this.props.lightTheme ? 'white' : '#222';
        var profileBlockStyle = {
            paddingRight: 10,
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        };
        return (React.createElement(Grid, { container: true, justify: "center", direction: "row", alignItems: "center" },
            this.props.users.map(u => {
                var selected = this.state.selectedUsers.filter(su => su == u).length > 0;
                return (React.createElement(Grid, { item: true, xs: this.props.isMobile ? 3 : 2, style: { maxWidth: "unset" } },
                    React.createElement("span", { onClick: () => this.selectUser(u), style: { color: selected ? '#BF0D3E' : textColor } },
                        React.createElement(KioskUserProfile, { user: u, selected: selected, useDarkText: !this.props.lightTheme }))));
            }),
            React.createElement(Grid, { item: true, xs: this.props.isMobile ? 3 : 2, style: { maxWidth: "unset" } },
                React.createElement("div", { className: "profile-block", style: profileBlockStyle },
                    React.createElement("div", { style: { textAlign: 'center' } },
                        React.createElement(IconButton, { onClick: () => this.setState({ addGuestOpen: true }) },
                            React.createElement(AddCircle, { style: { width: 75, height: 75, color: '#86898B' } }))),
                    React.createElement("div", { style: { height: 40, color: textColor, textAlign: "center", fontFamily: 'Gotham Medium', textTransform: 'uppercase' } }, "Add Guest")))));
    }
    render() {
        return (React.createElement("div", { style: { width: "100%" } },
            React.createElement("span", { style: { fontFamily: 'Big Noodle Titling', fontSize: 40, color: "#BF0D3E" } }, "Please select who is checking in"),
            this.userSelector(),
            React.createElement(Button, { color: "secondary", style: { margin: "25px", fontFamily: 'Gotham Book' }, variant: "contained", onClick: () => this.props.onCancelClick() }, "Cancel"),
            React.createElement(Button, { color: "secondary", style: { margin: "25px 0", fontFamily: 'Gotham Book' }, variant: "contained", onClick: () => this.onConfirm() }, "Confirm"),
            React.createElement(Dialog, { open: this.state.addGuestOpen },
                React.createElement(DialogTitle, null,
                    React.createElement("span", null, "Add Guest"),
                    React.createElement(IconButton, { "arial-label": "close", onClick: () => this.setState({ addGuestOpen: false }), style: { position: "absolute", right: 0, top: 0 } },
                        React.createElement(CloseIcon, null))),
                React.createElement(DialogContent, null,
                    React.createElement(AddGuestForm, { isMobile: this.props.isMobile, onSubmit: (fName, lName) => this.onAddGuest(fName, lName), onAddTGUser: (user) => this.onAddTGUser(user) })))));
    }
}
