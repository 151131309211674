import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { Grid, MuiThemeProvider, createMuiTheme, Typography } from '@material-ui/core';
import { authContext } from '../../../../Services/AzureAdAuth';
import { Device } from '../../../Device/Device';
import { ApiUtils } from '../../../../Services/ApiUtils';
export class Settings extends React.Component {
    constructor() {
        super(...arguments);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
    }
    //Render should show available settings.
    render() {
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(Paper, { style: { padding: 10 } },
                React.createElement(Grid, { container: true, spacing: 6 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "h5" }, "Account Settings")),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Button, { onClick: () => authContext.logOut(), variant: "contained", size: "small", color: "primary" }, "Logout")))),
            React.createElement(Paper, { style: { padding: 10 } },
                React.createElement(Grid, { container: true, spacing: 6 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "h5" }, "Device Settings")),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Button, { onClick: () => Device.refreshAllDeviceBrowsers(), variant: "contained", size: "small", color: "primary" }, "Refresh All Device Browsers")))),
            React.createElement(Paper, { style: { padding: 10 } },
                React.createElement(Grid, { container: true, spacing: 6 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "h5" }, "Cache Settings")),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Button, { onClick: () => ApiUtils.post(`RequestCache/ResetCaches`), variant: "contained", size: "small", color: "primary" }, "Clear Caches"))))));
    }
}
