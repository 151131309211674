import * as React from "react";
import { Snackbar } from "@material-ui/core";
import { DisplayType } from "../../../../Display/Display";
import { ApiUtils } from "../../../../../Services/ApiUtils";
import { DisplayConfigurationForm } from "./DisplayConfigurationForm";
import { LoadingSpinner } from "../../../../Common/Pages/LoadingSpinner";
export class DisplayConfiguration extends React.Component {
    getConfigData() {
        ApiUtils.get(`display/config/${this.props.displayId}`)
            .then(r => {
            let config = r.data.configuration;
            if (!config) {
                config = this.getDefaultConfig(r.data.type);
            }
            this.setState({
                configValues: config,
                displayType: r.data.type,
                saveSuccess: false,
            });
        });
    }
    getDefaultConfig(type) {
        if (type == DisplayType.Wallboard) {
            let result = {
                backgroundColor: '',
                backgroundImage: '',
                columnsPerPage: 3,
                rowsPerPage: 2,
                layoutDirection: true,
                titleText: '',
                fontColor: '',
                connectionErrorMessage: '',
                pageInterval: 10,
                promotionInterval: 25,
                waitTimeOffset: 60
            };
            return result;
        }
        else {
            let result = {
                backgroundColor: '',
                fontColor: '',
                connectionErrorMessage: '',
                promotionInterval: 25
            };
            return result;
        }
    }
    componentWillMount() {
        this.getConfigData();
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (typeof (this.state.configValues) == "string") {
            // If the JSON failed to parse, parse it again.
            let config = JSON.parse(this.state.configValues);
            config[name] = value;
            this.setState({
                configValues: config
            });
        }
        else {
            this.state.configValues[name] = value;
            this.setState({
                configValues: this.state.configValues
            });
        }
    }
    handleSave(event) {
        ApiUtils.post(`display/config/${this.props.displayId}`, this.state.configValues)
            .then(r => {
            this.setState({
                saveSuccess: true
            });
        });
    }
    render() {
        if (this.state && this.state.configValues) {
            var displayConfigProps = {
                displayType: this.state.displayType,
                configValues: this.state.configValues,
                onSave: (event) => this.handleSave(event),
                onChange: (event) => this.handleInputChange(event)
            };
            return (React.createElement("div", null,
                React.createElement(Snackbar, { anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }, open: this.state.saveSuccess, onClose: () => this.setState({ saveSuccess: false }), autoHideDuration: 60000, message: React.createElement("span", { id: "form-success-text" }, "Save successful") }),
                React.createElement(DisplayConfigurationForm, Object.assign({}, displayConfigProps))));
        }
        return (React.createElement(LoadingSpinner, null));
    }
}
