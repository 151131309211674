import * as React from 'react';
import { ForeignKeyDropdown } from "../../../Common/Fields/ForeignKeyDropdown";
import { ForeignKeyTable } from "../../../Common/Tables/ForeignKeyTable";
import { Device } from '../../../Device/Device';
//Renders a table of Devices with the matching store Id. 
export function DeviceTable(shopId) {
    var adTableProps = {
        apiController: "device",
        tableTitle: "",
        recordID: shopId || 0,
        foreignKeyField: 'shopId',
        columns: [
            { title: 'Id', field: 'id', type: 'numeric', editable: 'never' },
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            { title: 'Display Name', field: 'displayName', editable: 'never', emptyValue: 'None' },
            {
                title: 'Display Id', field: 'displayId', type: 'numeric', editable: 'always', editComponent: props => (React.createElement(ForeignKeyDropdown, { fieldName: 'displayId', onChange: props.onChange, nullable: true, value: props.value, apiController: "Display" }))
            },
            { title: 'ShopId', field: 'shopId', hidden: true, editable: 'onAdd', type: 'numeric', emptyValue: shopId }
        ],
        actions: [
            {
                icon: "refresh",
                tooltip: 'Refresh',
                onClick: (event, rowData) => {
                    Device.refreshDeviceBrowser(rowData.id);
                }
            },
            {
                icon: 'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => {
                    window.open(`/Device/${rowData.id}`, '_blank');
                }
            }
        ]
    };
    return (React.createElement(ForeignKeyTable, Object.assign({}, adTableProps)));
}
