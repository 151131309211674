import * as React from "react";
import { ShortcutsServiceWindow } from "../../../Common/Windows/ShortcutsServiceWindow";
import { ForeignKeyTable } from "../../../Common/Tables/ForeignKeyTable";
import { ForeignKeyDropdown } from "../../../Common/Fields/ForeignKeyDropdown";
import { ApiUtils } from "../../../../Services/ApiUtils";
export class ServiceTable extends React.Component {
    constructor(props) {
        super(props);
        this.tableKey = 0;
        this.tableProps = {
            apiController: "service",
            apiAction: "ReadFromServiceCategory",
            tableTitle: "Shortcut Services",
            recordID: this.props.serviceCategoryId,
            foreignKeyField: 'ServiceCategoryId',
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Description', field: 'description' },
                { title: 'Shortcuts Id', field: 'shortcutsId', type: 'numeric' },
                { title: 'On Premise Id', field: 'onPremiseId', type: 'numeric' },
                { title: 'Sort Order', field: 'sortOrder', type: 'numeric' },
                { title: "Enhancement Sort Order", field: 'enhancementSortOrder', type: 'numeric' },
                { title: 'ServiceCategoryId', field: 'serviceCategoryId', type: 'numeric', hidden: true, editable: 'onAdd', emptyValue: this.props.serviceCategoryId },
                {
                    title: 'Icon', field: 'assetId', render: (data, type) => (React.createElement("div", null, (data && data.asset) ? data.asset.name : '')), editComponent: props => React.createElement(ForeignKeyDropdown, { onChange: props.onChange, fieldName: 'assetID', nullable: true, value: props.value, apiController: "asset", apiAction: "ServiceImages" })
                },
            ],
            actions: [
                {
                    icon: 'cloud_download',
                    tooltip: 'Add from Shortcuts',
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                        this.setState({
                            modalOpen: true,
                            tableKey: ++this.tableKey
                        });
                    }
                },
                {
                    icon: 'loop',
                    tooltip: 'Sync on premise from Shortcuts',
                    isFreeAction: false,
                    onClick: (event, rowData) => {
                        this.syncOnPremise(rowData.id);
                    }
                }
            ],
            detailPanelActions: [
                {
                    icon: 'visibility',
                    openIcon: 'visibility_off',
                    tooltip: 'Preview',
                    render: rowData => {
                        if (rowData.asset) {
                            return (React.createElement("img", { width: "100px", height: "100px", src: `/api/v1/asset/view/${rowData.asset.id}`, alt: `${rowData.Name}` }));
                        }
                        else {
                            return (React.createElement("div", null, "No image set"));
                        }
                    }
                }
            ]
        };
        this.handleClose = () => {
            this.setState({ modalOpen: false });
        };
        this.handleSave = () => {
            this.setState({
                modalOpen: false,
                tableKey: ++this.tableKey,
            });
        };
        this.state = {
            modalOpen: false,
            tableKey: 0
        };
    }
    syncOnPremise(id) {
        ApiUtils.post(`service/UpdateOnPremiseId/${id}`).then(r => {
            if (r.status == 200) {
                this.setState({
                    tableKey: ++this.tableKey,
                });
            }
        });
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(ShortcutsServiceWindow, { serviceCategoryId: this.props.serviceCategoryId, open: this.state.modalOpen, onClose: this.handleClose, onSave: this.handleSave }),
            React.createElement(ForeignKeyTable, Object.assign({ key: this.state.tableKey }, this.tableProps))));
    }
}
