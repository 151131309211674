import * as React from 'react';
export var TableType;
(function (TableType) {
    TableType[TableType["None"] = 0] = "None";
    TableType[TableType["Assets"] = 1] = "Assets";
    TableType[TableType["Promotions"] = 2] = "Promotions";
    TableType[TableType["Displays"] = 3] = "Displays";
    TableType[TableType["Services"] = 4] = "Services";
    TableType[TableType["Shops"] = 5] = "Shops";
    TableType[TableType["Users"] = 6] = "Users";
    TableType[TableType["MobilePromotions"] = 7] = "MobilePromotions";
    TableType[TableType["ReportedIssues"] = 8] = "ReportedIssues";
    TableType[TableType["TestAccounts"] = 9] = "TestAccounts";
})(TableType || (TableType = {}));
class TableStateManager {
    constructor() {
        this.tables = {};
        this.states = {
            assets: this.defaultState(),
            displays: this.defaultState(),
            promotions: this.defaultState(),
            services: this.defaultState(),
            shops: this.defaultState(),
            users: this.defaultState(),
        };
        this.tables["Assets"] = null;
    }
    defaultState() {
        var dState = {
            page: 0,
            pageSize: 20,
            filter: []
        };
        return dState;
    }
    accessTable(tableName) {
        if (this.tables[tableName].value) {
            return this.tables[tableName].value;
        }
        else {
            var ref = React.createRef();
            this.tables[tableName].value = ref;
            return ref;
        }
    }
    getTable(tableRef, type = TableType.None) {
        switch (type) {
            case TableType.Assets:
                return this.accessTable("Assets");
            case TableType.Displays:
                return this.accessTable("Displays");
            case TableType.Promotions:
                return this.accessTable("Promotions");
            case TableType.Services:
                return this.accessTable("Services");
            case TableType.Shops:
                return this.accessTable("Shops");
            case TableType.Users:
                return this.accessTable("Users");
            case TableType.None:
                return null;
        }
    }
    getStateForType(type = TableType.None) {
        switch (type) {
            case TableType.Assets:
                return this.states.assets;
            case TableType.Displays:
                return this.states.displays;
            case TableType.Promotions:
                return this.states.promotions;
            case TableType.Services:
                return this.states.services;
            case TableType.Shops:
                return this.states.shops;
            case TableType.Users:
                return this.states.users;
            default:
                return this.defaultState();
        }
    }
    updateStateForType(state, type = TableType.None) {
        switch (type) {
            case TableType.Assets:
                this.updateAssetState(state);
                break;
            case TableType.Displays:
                this.updateDisplayState(state);
                break;
            case TableType.Promotions:
                this.updatePromotionState(state);
                break;
            case TableType.Services:
                this.updateServiceState(state);
                break;
            case TableType.Shops:
                this.updateShopState(state);
                break;
            case TableType.Users:
                this.updateUserState(state);
                break;
            default:
                return;
        }
    }
    updateAssetState(state) {
        this.states.assets = state;
    }
    updateServiceState(state) {
        this.states.services = state;
    }
    updatePromotionState(state) {
        this.states.promotions = state;
    }
    updateDisplayState(state) {
        this.states.displays = state;
    }
    updateShopState(state) {
        this.states.shops = state;
    }
    updateUserState(state) {
        this.states.users = state;
    }
}
export const tableStates = new TableStateManager();
