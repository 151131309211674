import * as React from "react";
export class BarberSquare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected || false
        };
    }
    onSelection() {
        this.props.onSelection(this.props.barber);
        //this.setState({
        //    selected: !this.state.selected
        //})
    }
    renderWaitTime() {
        // TEMP: Per barber wait time calculation is currently not supported by Shortcuts
        if (!this.props.hideWaitTime) {
            if (this.props.isMobile) {
                return (React.createElement("div", null,
                    React.createElement("div", { className: "tg-barber-square__wait-text", style: { fontSize: "15px", paddingTop: "15px", color: "#bcbcbc" } }, "Estimated wait time: "),
                    React.createElement("div", { className: "tg-barber-square__wait-value", style: { paddingTop: "10px", fontSize: 20 } },
                        this.props.barber.waitTime,
                        " mins")));
            }
            else {
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "tg-barber-square__wait-text", style: { width: "35%", float: "left", fontSize: "10px", paddingTop: "10px", color: "#bcbcbc" } }, "Estimated wait time: "),
                    React.createElement("div", { className: "tg-barber-square__wait-value", style: { width: "60%", float: "right", paddingTop: "20px", fontSize: 15 } },
                        this.props.barber.waitTime,
                        " mins")));
            }
        }
        else
            return (React.createElement("div", null));
    }
    render() {
        var barberSquareStyle = {
            backgroundColor: this.props.selected ? '#2c2c2c' : '#fefefe',
            color: this.props.selected ? '#fefefe' : '#2c2c2c',
            padding: this.props.selected ? 22 : 25,
            borderRadius: 10,
            border: this.props.selected ? "3px solid #BF0D3E" : "",
            height: "100%",
            cursor: "pointer"
        };
        var containerStyle = {
            paddingBottom: 20,
            width: this.props.overideSize || "200px",
            height: this.props.overideSize || "195px",
            marginLeft: 10,
            float: "left"
        };
        var mobileBarberSquareStyle = {
            backgroundColor: this.props.selected ? '#2c2c2c' : '#fefefe',
            color: this.props.selected ? '#fefefe' : '#2c2c2c',
            padding: 10,
            borderRadius: 10,
            border: this.props.selected ? "3px solid #BF0D3E" : "",
            height: "100%",
            cursor: "pointer"
        };
        var mobileContainerStyle = {
            paddingBottom: 20,
            width: 145,
            height: 182,
            marginLeft: 10,
            float: "left"
        };
        if (this.props.isMobile) {
            return (React.createElement("div", { onClick: () => this.onSelection(), className: `tg-barber-square-container ${this.props.selected ? "tg-selected" : ""}`, style: mobileContainerStyle },
                React.createElement("div", { className: "tg-barber-square", style: mobileBarberSquareStyle },
                    React.createElement("div", { className: "tg-barber-square__barber", style: {} },
                        React.createElement("div", { className: "tg-barber-square__name", style: { fontSize: 20, fontFamily: 'Big Noodle Titling' } }, this.props.barber.name)),
                    React.createElement("div", { className: "tg-barber-square__details", style: { borderTop: "2px #BF0D3E solid", fontFamily: 'Gotham Book' } }, this.renderWaitTime()))));
        }
        else {
            return (React.createElement("div", { onClick: () => this.onSelection(), className: `tg-barber-square-container ${this.props.selected ? "tg-selected" : ""}`, style: containerStyle },
                React.createElement("div", { className: "tg-barber-square", style: barberSquareStyle },
                    React.createElement("div", { className: "tg-barber-square__barber", style: { height: "60%" } },
                        React.createElement("div", { className: "tg-barber-square__name", style: { fontSize: this.props.overideSize ? 38 : 30, fontFamily: 'Big Noodle Titling' } }, this.props.barber.name)),
                    React.createElement("div", { className: "tg-barber-square__details", style: { display: "flex", borderTop: "2px #BF0D3E solid", fontFamily: 'Gotham Book' } }, this.renderWaitTime()))));
        }
    }
}
