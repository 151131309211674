import * as React from "react";
import { TreeTable } from "../../../Common/Tables/TreeTable";
export class RegionTable extends React.Component {
    render() {
        var tableProps = {
            apiController: 'region',
            tableTitle: 'Regions',
            parentFieldName: 'parentRegionId',
            editableRegion: true,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', hidden: true, editable: 'never' },
                { title: 'Name', field: 'name', cellStyle: (data, rowData) => { return { paddingLeft: 24 * ((rowData) ? rowData.tableData.path.length : 1) }; } },
                { title: 'Description', field: 'description' },
                { title: 'Parent Region', field: 'parentRegionId', type: 'numeric', editable: 'never', hidden: true },
                { title: 'Child Regions', field: 'childRegionCount', type: 'numeric', editable: 'never' },
                { title: 'Shops', field: 'shopsCount', type: 'numeric', editable: 'never' },
                { title: 'Promotions', field: 'promotionsCount', type: 'numeric', editable: 'never' },
                { title: 'Displays', field: 'displaysCount', type: 'numeric', editable: 'never' }
            ],
            actions: [
                {
                    icon: 'send',
                    tooltip: 'Set active Region',
                    onClick: this.props.onRegionChange
                }
            ]
        };
        return (React.createElement(TreeTable, Object.assign({}, tableProps)));
    }
}
