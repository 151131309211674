import { ApiUtils } from "../../../Services/ApiUtils";
import { EntityTable } from "./EntityTable";
//TODO: Should this extend EntityTable?
export class ForeignKeyTable extends EntityTable {
    onAdd(data) {
        data[this.props.foreignKeyField] = this.props.recordID;
        return (ApiUtils.post(`${this.props.apiController}/Create`, data).then(r => {
            console.log(r);
        }));
    }
    getData(query) {
        var url = `${this.props.apiController}/${this.props.apiAction || "Read"}/${this.props.recordID}?`;
        if (query && query.pageSize)
            url += `pageSize=${query.pageSize}&`;
        if (query && query.page)
            url += `page=${query.page}`;
        return ApiUtils.get(url).then(r => {
            return {
                data: r.data.data,
                page: r.data.page,
                totalCount: r.data.totalCount
            };
        });
    }
}
