import * as React from 'react';
import { KioskUserProfile } from './KioskUser';
import { Grid, Button } from '@material-ui/core';
// Interface for the user to select who is checking in.
export class UserSelector extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.users.length == 1) {
            this.props.onConfirm(this.props.users[0]);
        }
        this.state = {
            selectedUser: this.props.users[0],
        };
    }
    onSelect(user) {
        if (user == this.state.selectedUser)
            return;
        this.setState({
            selectedUser: user
        });
        this.forceUpdate();
    }
    onConfirm() {
        if (this.state.selectedUser)
            this.props.onConfirm(this.state.selectedUser);
    }
    renderUserProfile(user) {
        var selected = this.state.selectedUser == user;
        var style = {
            color: selected ? '#BF0D3E' : '#222',
            maxWidth: "unset",
        };
        return (React.createElement(Grid, { item: true, xs: 2, onClick: () => this.onSelect(user), style: style },
            React.createElement(KioskUserProfile, { user: user, selected: selected })));
    }
    render() {
        if (this.props.users.length == 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement("h1", null, "Everyones checked in! Enjoy your service."),
                    React.createElement(Button, { onClick: () => this.props.onComplete, variant: "outlined", color: "secondary" }, "Confirm"))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement("div", { style: { fontFamily: 'Big Noodle Titling', fontSize: 40, color: "#BF0D3E" } }, "Who's checking in?")),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Grid, { container: true, justify: "center", alignContent: "center", alignItems: "center" }, this.props.users.map(u => (this.renderUserProfile(u))))),
            React.createElement(Grid, { item: true, xs: 12, justify: "center", style: { margin: "25px auto" } },
                React.createElement(Button, { onClick: () => this.onConfirm(), variant: "contained", color: "secondary" }, "Confirm"))));
    }
}
