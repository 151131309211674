import * as React from 'react';
import { Display } from "./Display";
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { PromotionQueue } from './Promotion/PromotionQueue';
import { ApiUtils } from "../../Services/ApiUtils";
import { LoadingSpinner } from '../Common/Pages/LoadingSpinner';
export class DigitalSign extends Display {
    constructor() {
        super(...arguments);
        this.loadFailure = false;
    }
    loadData() {
        return ApiUtils.get(`Display/DigitalSign/${this.props.shopId}`).then(r => { return r.data; });
    }
    update() {
        this.loadData().then(res => {
            this.setState({
                promotions: res.promotions
            });
        })
            .catch(err => {
            this.setState({
                error: true
            });
        });
    }
    componentWillMount() {
        if (this.promotionChangeInterval)
            this.intervalId = window.setInterval(() => this.update(), this.promotionChangeInterval);
        else
            this.update();
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
    componentDidCatch(err) {
        console.log(`Error with Device ${this.props.deviceId}`);
        console.log(err);
        if (!this.loadFailure) {
            this.loadData().catch(e => this.loadFailure = true);
        }
    }
    render() {
        if (!this.state) {
            return (React.createElement(LoadingSpinner, null));
        }
        else if (this.state.error) {
            //TODO: The error message should render in it's own component.
            return (React.createElement(MuiThemeProvider, { theme: this.theme },
                React.createElement(CssBaseline, null),
                React.createElement("h1", { className: "wallboard-error", style: { color: this.props.config.fontColor || "white", textAlign: "center", margin: 0 } }, this.props.config.connectionErrorMessage || "Connection issue, No data was loaded")));
        }
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(CssBaseline, null),
            React.createElement(PromotionQueue, { refreshData: () => this.update(), promotions: this.state.promotions, changeInterval: this.promotionChangeInterval })));
    }
}
