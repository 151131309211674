import { AuthenticationContext, withAdalLogin } from "react-adal";
import * as dotenv from "dotenv";
dotenv.config({ path: __dirname + '/.env' });
if (!process.env.REACT_APP_TENANT || !process.env.REACT_APP_CLIENT_ID || !process.env.REACT_APP_API_ENDPOINT || !process.env.REACT_APP_CACHE_LOCATION) {
    console.log("One or more .env variables undefined");
}
const adalConfig = {
    tenant: process.env.REACT_APP_TENANT,
    clientId: process.env.REACT_APP_CLIENT_ID,
    endpoints: {
        api: process.env.REACT_APP_API_ENDPOINT
    },
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION
};
export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.clientId);
export const auth = process.env.REACT_APP_APIKEY || '';
