import * as React from "react";
import { Grid, Button } from "@material-ui/core";
export default class CheckinCompletion extends React.Component {
    renderMessage() {
        if (this.props.promotionImage) {
            return (React.createElement("img", { style: { width: "50%", margin: "auto" }, src: `/api/v1/asset/view/${this.props.promotionImage}` }));
        }
        else {
            return (React.createElement("div", null, "Thank you!"));
        }
    }
    render() {
        setTimeout(() => this.props.onComplete(), this.props.duration);
        var backgroundOverlay = 'linear-gradient( rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85) ),';
        var backgroundStyle = this.props.backgroundImage ? `${backgroundOverlay} url('/api/v1/asset/view/${this.props.backgroundImage}')` : '#111';
        var styles = {
            backgroundImage: backgroundStyle,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            height: "100%",
            textAlign: "center"
        };
        return (
        // Display thank you text
        // Display Check-in button
        React.createElement(Grid, { container: true, justify: "center", alignItems: "center", style: styles },
            React.createElement(Grid, { item: true, xs: 12 }, this.renderMessage()),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Button, { style: { background: "#BF0D3E", color: "white", fontFamily: 'Gotham Book' }, onClick: (event) => { this.props.onComplete(); }, variant: "contained" }, "Check-In New Guest"))));
    }
}
