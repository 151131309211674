import * as React from "react";
export class BarberSquareSimple extends React.Component {
    constructor(props) {
        super(props);
    }
    renderWaitTime() {
        // TEMP: Per barber wait time calculation is currently not supported by Shortcuts
        if (!this.props.hideWaitTime) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "tg-barber-square__wait-text", style: { width: "35%", float: "left", fontSize: "15px", paddingTop: "15px", color: "#bcbcbc" } }, "Estimated wait time: "),
                React.createElement("div", { className: "tg-barber-square__wait-value", style: { width: "60%", float: "right", paddingTop: "20px", fontSize: 20 } },
                    this.props.barber.waitTime,
                    " mins")));
        }
        else
            return (React.createElement("div", null));
    }
    render() {
        var barberSquareStyle = {
            backgroundColor: this.props.selected ? '#2c2c2c' : '#fefefe',
            color: this.props.selected ? '#fefefe' : '#2c2c2c',
            padding: this.props.selected ? 22 : 25,
            borderRadius: 10,
            border: this.props.selected ? "3px solid #BF0D3E" : "",
            height: "100%"
        };
        var containerStyle = {
            paddingBottom: 20,
            width: this.props.overideSize || "300px",
            height: 134 || "300px",
            float: "left"
        };
        return (React.createElement("div", { className: `tg-barber-square-container`, style: containerStyle },
            React.createElement("div", { className: "tg-barber-square", style: barberSquareStyle },
                React.createElement("div", { className: "tg-barber-square__barber", style: { height: "60%" } },
                    React.createElement("div", { className: "tg-barber-square__name", style: { fontSize: 20, fontFamily: 'Big Noodle Titling', borderBottom: "2px #BF0D3E solid" } }, this.props.barber.name)))));
    }
}
