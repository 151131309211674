import * as React from "react";
import { NavLink } from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import { AuthContext } from "../../Services/AuthContext";
export function SideNav(props) {
    return (React.createElement(MenuList, null, props.items.map((x) => {
        return (React.createElement(NavLink, { to: x.to, activeClassName: "active", className: "side-nav-link" },
            React.createElement(MenuItem, { style: { fontSize: '1.1em' }, className: "side-nav-item", title: x.text },
                React.createElement(Icon, { fontSize: "large" }, x.icon),
                " ",
                x.text)));
    })));
}
export class SecuredMenuItem extends React.Component {
    render() {
        return (React.createElement(AuthContext.Consumer, null, ({ authService }) => {
            if (!authService) {
                throw new Error("Fatal error: authService was not available in SecuredMenuItem");
            }
            if (!authService.currentUser) {
                return null;
            }
            if (!authService.currentUser.matchesAnyRole(this.props.visibleToRoles)) {
                return null;
            }
            return (React.createElement(NavLink, { to: this.props.to, activeClassName: "active", className: "side-nav-link" },
                React.createElement(MenuItem, { style: { fontSize: '1.1em' }, className: "side-nav-item", title: this.props.text },
                    React.createElement(Icon, { fontSize: "large" }, this.props.icon),
                    " ",
                    this.props.text)));
        }));
    }
}
