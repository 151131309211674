import * as React from "react";
import { Paper, TextField, FormControl, Button, Switch, FormControlLabel } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import { ForeignKeyDropdown } from "../../../../Common/Fields/ForeignKeyDropdown";
export function WallboardConfigurationForm(props) {
    var config = (typeof (props.configValues) == "string") ? JSON.parse(props.configValues) : props.configValues;
    return (React.createElement(Paper, { style: { paddingTop: 25 } },
        React.createElement("form", { onSubmit: props.onSave },
            React.createElement(Grid, { container: true, spacing: 8 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { alignItems: "center", style: { textAlign: "center" }, container: true, justify: "center", spacing: 4 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "rowsPerPage", name: "rowsPerPage", variant: "outlined", label: "Rows per page", value: config.rowsPerPage, onChange: props.onChange, className: "wallboard-config-input" }),
                                React.createElement(TextField, { id: "columnsPerPage", name: "columnsPerPage", variant: "outlined", label: "Columns per page", value: config.columnsPerPage, onChange: props.onChange, className: "wallboard-config-input" }),
                                React.createElement(TextField, { id: "titleText", name: "titleText", variant: "outlined", label: "Title text", value: config.titleText, onChange: props.onChange, className: "wallboard-config-input" }),
                                React.createElement(FormControlLabel, { control: React.createElement(Switch, { id: "layoutDirection", name: "layoutDirection", color: "primary", checked: config.layoutDirection, value: config.layoutDirection, onChange: props.onChange, className: "wallboard-config-input" }), label: "Enable Top to Bottom Layout", labelPlacement: "top" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "fontColor", name: "fontColor", variant: "outlined", label: "Font color", value: config.fontColor, onChange: props.onChange, className: "wallboard-config-input" }),
                                React.createElement(TextField, { id: "backgroundColor", name: "backgroundColor", variant: "outlined", label: "Background color", value: config.backgroundColor, onChange: props.onChange, className: "wallboard-config-input" }),
                                React.createElement(FormControlLabel, { control: React.createElement(ForeignKeyDropdown, { fieldName: 'backgroundImage', onChange: props.onChange, nullable: true, value: config.backgroundImage, apiController: "Asset", returnEvent: true }), label: "Background Image", labelPlacement: "top" }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4, justify: "center" },
                            React.createElement(FormControl, null,
                                React.createElement(TextField, { id: "waitTimeOffset", name: "waitTimeOffset", variant: "outlined", label: "Waiting Time Offset (s)", value: config.waitTimeOffset, onChange: props.onChange, className: "wallboard-config-input" }),
                                React.createElement(TextField, { id: "pageInterval", name: "pageInterval", variant: "outlined", label: "Page Interval (s)", value: config.pageInterval, onChange: props.onChange, className: "wallboard-config-input" }),
                                React.createElement(TextField, { id: "promotionInterval", name: "promotionInterval", variant: "outlined", label: "Promotion Interval (s)", value: config.promotionInterval, onChange: props.onChange, className: "wallboard-config-input" }))),
                        React.createElement(Grid, { item: true, xs: 10, sm: 10, justify: "center" },
                            React.createElement(FormControl, { fullWidth: true },
                                React.createElement(TextField, { id: "connectionErrorMessage", name: "connectionErrorMessage", variant: "outlined", label: "Connection Error Message", value: config.connectionErrorMessage, onChange: props.onChange, className: "wallboard-config-input", multiline: true }))),
                        React.createElement(Grid, { item: true, xs: 12, justify: "center" },
                            React.createElement(Button, { style: { maxWidth: "80px", margin: "auto" }, variant: "contained", size: "small", color: "primary", onClick: props.onSave },
                                React.createElement(SaveIcon, null),
                                "Save"))))))));
}
