import axios from 'axios';
import * as _ from 'lodash';
import { getToken, authContext, auth } from './AzureAdAuth';
import crypto from 'crypto';
export class ApiUtils {
    static error(data) {
        // TODO: This needs to hit an endpoint on the server.
        axios.post('http://localhost:5341/api/events/raw', data);
    }
    static configure(token) {
        if (!token)
            token = getToken();
        axios.interceptors.request.use(request => {
            request.headers.Authorization = `Bearer ${token}`;
            this.authToken = token;
            return request;
        });
        axios.interceptors.response.use(response => { return response; }, error => {
            console.log('Api request error code:  ' + error.response.status);
            if (401 === error.response.status) {
                authContext.login();
                var oldToken = this.authToken;
                this.authToken = getToken();
                if (this.authToken != '' && this.authToken != oldToken)
                    window.location.href = "/";
            }
            else
                return error;
        });
    }
    static get(url, prototype) {
        return axios.get(this.baseUri + url).then(response => {
            if (prototype)
                response.data = _.create(prototype, response.data);
            return response;
        });
    }
    static post(url, data, config, prototype) {
        return axios.post(this.baseUri + url, data, config).then(response => {
            if (prototype) {
                response.data = _.create(prototype, response.data);
            }
            return response;
        });
    }
    static delete(url) {
        return axios.delete(this.baseUri + url);
    }
}
ApiUtils.baseUri = '/api/v1/';
ApiUtils.authToken = '';
ApiUtils.key = crypto.createHash('sha256').update(auth).digest('hex') || '';
