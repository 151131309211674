import * as React from "react";
export function Promotion(props) {
    return (React.createElement("div", { style: { textAlign: "center", bottom: 0, position: "absolute" } },
        React.createElement("img", { className: "promotion-image", src: props.contentUrl, alt: "Marketing Content" })));
}
export class PromotionViewer extends React.Component {
    render() {
        return React.createElement("img", { className: "promotion-image", src: `/api/v1/promotion/view/${this.props.id}`, alt: "Marketing Content" });
    }
}
