import * as React from "react";
import { ApiUtils } from "../../../Services/ApiUtils";
import MaterialTable from 'material-table';
import { OverviewPage } from '../../Common/Pages/OverviewPage';
import { Button, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { ArrowBack } from '@material-ui/icons';
import { ChangeRegionWindow } from "../Windows/ChangeRegionWindow";
import { tableStates } from "../../../Services/TableStateManager";
export class EntityTable extends React.Component {
    constructor() {
        super(...arguments);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
    }
    componentDidMount() {
        this.setState({
            tableKey: 0,
            tableState: tableStates.getStateForType(this.props.type)
        });
    }
    onDelete(data) {
        return (ApiUtils.delete(`${this.props.apiController}/Delete/${data.id}`).then(r => {
        }));
    }
    onUpdate(newData, oldData) {
        return (ApiUtils.post(`${this.props.apiController}/Update`, newData).then(r => {
            this.setState({
                openedRow: null,
                modalOpen: false,
                overviewOpen: false,
                tableKey: this.state.tableKey == 0 ? 1 : 0
            });
        }));
    }
    onAdd(data) {
        return (ApiUtils.post(`${this.props.apiController}/Create`, data).then(r => {
        }));
    }
    onOpen(event, data) {
        this.setState({
            openedRow: data,
            overviewOpen: true,
        });
    }
    updateData(query) {
        // TODO: The Gft API needs to return a model simlar to the query result.
        return (this.getData(query).then(r => {
            if (r.data) {
                return {
                    data: r.data,
                    page: r.page,
                    totalCount: r.totalCount
                };
            }
            else {
                console.log('Error:');
                console.log(r);
            }
        }));
    }
    getTableActions() {
        var actions = (this.props.overviewComponent != null) ? [
            {
                icon: 'launch',
                tooltip: 'Open',
                onClick: (event, rowData) => {
                    this.onOpen(event, rowData);
                }
            }
        ] : [];
        if (this.props && this.props.editableRegion) {
            let action = {
                icon: 'edit_location',
                tooltip: 'Change Region',
                onClick: (event, rowData) => {
                    this.setState({
                        modalOpen: true,
                        openedRow: rowData
                    });
                }
            };
            actions = actions.concat(action);
        }
        if (this.props && this.props.actions) {
            actions = actions.concat(this.props.actions);
        }
        return actions;
    }
    getData(query) {
        var tState = tableStates.getStateForType(this.props.type);
        var url = `${this.props.apiController}/${this.props.apiAction || "Read"}?`;
        if (query && query.pageSize)
            url += `pageSize=${query.pageSize}&`;
        else
            url += `pageSize=${tState.pageSize}&`;
        if (query && query.page)
            url += `page=${query.page}`;
        else
            url += `page=${tState.page}`;
        return ApiUtils.get(url).then(r => {
            if (r.data) {
                this.updateTableState();
                return { data: r.data.data, page: r.data.page, totalCount: r.data.totalCount };
            }
            else
                return { data: [], page: 0, totalCount: 0 };
        });
    }
    updateTableState() {
        var tState = tableStates.getStateForType(this.props.type);
        this.setState({
            tableState: {
                filter: tState.filter,
                page: tState.page,
                pageSize: tState.pageSize
            }
        });
    }
    onChangePage(page, pageSize) {
        var tState = this.state.tableState;
        tState.page = page;
        tState.pageSize = pageSize;
        tableStates.updateStateForType(tState, this.props.type);
    }
    onChangeRowsPerPage(pageSize) {
        var tState = this.state.tableState;
        tState.pageSize = pageSize;
        tableStates.updateStateForType(tState, this.props.type);
    }
    onFiltersChange(filters) {
    }
    render() {
        if (this.state && this.props.overviewComponent != null && this.state.openedRow != null && this.state.overviewOpen) {
            return (React.createElement(MuiThemeProvider, { theme: this.theme },
                React.createElement("div", { onClick: () => this.setState({ openedRow: null, overviewOpen: false }), style: { margin: 8 * 3 }, "aria-label": "Breadcrumb" }, this.props.overviewComponent.props.breadCrumbs.map((b) => { return (React.createElement(Button, null,
                    React.createElement(ArrowBack, null),
                    " ",
                    b)); })),
                React.createElement(OverviewPage, Object.assign({ recordID: this.state.openedRow.id }, this.props.overviewComponent.props))));
        }
        else {
            var initalPage = (this.state && this.state.tableState.page) ? this.state.tableState.page : 0;
            var pageSize = (this.state && this.state.tableState.pageSize) ? this.state.tableState.pageSize : 0;
            return (React.createElement(MuiThemeProvider, { theme: this.theme },
                this.state && this.state.openedRow != null &&
                    React.createElement(ChangeRegionWindow, { open: this.state.modalOpen, onClose: () => this.setState({ openedRow: null, modalOpen: false }), onSave: (newData, oldData) => this.onUpdate(newData, oldData), record: this.state.openedRow }),
                React.createElement(MaterialTable, { key: this.state && this.state.tableKey, ref: r => (this.tableRef = tableStates.getTable(this.props.type)), title: this.props.tableTitle, columns: this.props.columns, options: {
                        headerStyle: {
                            backgroundColor: 'transparent'
                        },
                        loadingType: 'linear',
                        paging: true,
                        pageSize: pageSize,
                        sorting: true
                    }, data: (query) => this.updateData(query)
                        .then(r => {
                        return r;
                    }), onChangePage: (page, pageSize) => this.onChangePage(page, pageSize), onChangeRowsPerPage: (pageSize) => this.onChangeRowsPerPage(pageSize), onFilterChange: (filters) => this.onFiltersChange(filters), actions: this.getTableActions(), editable: {
                        onRowAdd: (data) => this.onAdd(data),
                        onRowUpdate: (newData, oldData) => this.onUpdate(newData, oldData),
                        onRowDelete: (data) => this.onDelete(data),
                    }, detailPanel: this.props.detailPanelActions })));
        }
    }
}
