import * as React from "react";
import { MuiThemeProvider, createMuiTheme, AppBar, Tabs, Tab } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { MobilePromotionsTable } from "./MobilePromotionsTable";
import { MobileConfiguration } from "./MobileConfiguration";
import { ReportedIssuesTable } from "../Tables/ReportedIssues";
export class MobileSettings extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: "#BF0D3E"
                },
                secondary: {
                    main: "#fff"
                }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: '#1E1E1E'
                    }
                },
            }
        });
        this.state = {
            currentIndex: 0,
        };
    }
    handleChange(event, newValue) {
        this.setState({
            currentIndex: newValue
        });
    }
    //Render should show available settings.
    render() {
        return (React.createElement(MuiThemeProvider, { theme: this.theme },
            React.createElement(AppBar, { position: "static", style: { backgroundColor: '#171913' } },
                React.createElement(Tabs, { value: this.state.currentIndex, onChange: (event, newValue) => this.handleChange(event, newValue) },
                    React.createElement(Tab, { label: "Promotions", value: 0 }),
                    React.createElement(Tab, { label: "Reported Issues", value: 1 }),
                    React.createElement(Tab, { label: "Configuration", value: 2 }))),
            React.createElement("div", { hidden: this.state.currentIndex != 0 },
                React.createElement(Paper, null,
                    React.createElement(MobilePromotionsTable, null))),
            React.createElement("div", { hidden: this.state.currentIndex != 1 },
                React.createElement(Paper, null,
                    React.createElement(ReportedIssuesTable, null))),
            React.createElement("div", { hidden: this.state.currentIndex != 2 },
                React.createElement(Paper, null,
                    React.createElement(MobileConfiguration, null)))));
    }
}
